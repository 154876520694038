$resolution-2k: 2560px;
$resolution-2k-multiplier: 1.2;
$resolution-4k: 3840px;
$resolution-4k-multiplier: 1.7;
// WHEN YOU CHANGE THIS VALUES, ALSO CHANGE IT IN screenResolutionConstants.ts file

@function calculate2kValue($value) {
  @if ($value) {
    @return $value * $resolution-2k-multiplier;
  } @else {
    @error "Please provide a valid value" $value;
  }
}

@function calculate4kValue($value) {
  @if ($value) {
    @return $value * $resolution-4k-multiplier;
  } @else {
    @error "Please provide a valid value" $value;
  }
}
