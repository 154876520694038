.holderWrapButton {
  .button {
    margin-top: 40px;
    padding: 11px 0;
    font-weight: var(--font-weight);
    font-size: 12px;
  }

  @media screen and (max-width: 1367px) {
    .button {
      margin-top: 10px;
      padding: 5px 0;
    }
    span {
      font-size: 12px;
    }
  }
}

.disabled-link {
  pointer-events: none;
}

.newsContent {
  background-color: #fff;
  height: -webkit-fill-available;
  padding: 15px 10%;
  overflow: auto;

  .paragraph {
    color: #666666;
    span {
      color: #666666;
    }
    a {
      color: #666666;
      text-decoration: underline;
    }
    padding: 15px 0;
    ul {
      margin-left: 30px;
    }
    ul,
    li {
      list-style: none;
    }
    img {
      width: 10px;
      height: 10px;
    }
  }

  .colored {
    color: #e69138 !important;
  }
  .note {
    color: #666666;
    font-weight: bold;
  }
  .center {
    text-align: center;
  }
  .fullImage {
    object-fit: contain;
    width: 100% !important;
    height: auto !important;
  }
  .right_svg {
    fill: #fff;
  }

  .asideImage {
    object-fit: contain;
    width: 50px !important;
    height: auto !important;
  }

  .asideBigImage {
    object-fit: contain;
    width: 140px !important;
    height: auto !important;
  }

  .icon {
    object-fit: contain;
    width: 24px !important;
    height: auto !important;
  }
}
.wrapAsideSelect {
  max-width: 157px;
  width: 157px;
  margin-left: 50px;
  background: var(--background-grid);

  &.-disabled {
    background: var(--background-isDeactivated);
  }

  @media screen and (max-width: 1200px) {
    margin-left: 0;
  }

  @media screen and (max-width: 560px) {
    max-width: 100px;
    width: 100px;
  }
}

.aside__select {
  //margin: 0 20px;
  height: 35px !important;
  width: calc(100%);
  background-color: transparent !important;

  &.-disabled {
    opacity: 0.1;

    svg {
      path {
        fill: var(--color);
      }
    }
  }

  &:focus {
    background-color: transparent !important;
  }

  em {
    margin-left: 10px;
    color: var(--color);
    font-weight: var(--font-weight);
    font-size: 14px;
    font-style: normal;
  }
  //padding: 0 5px;
  &:before {
    content: none !important;
  }
  @media screen and (max-width: 980px) {
    margin-left: 0;
    padding-right: 0;
    width: 100%;
  }

  &__clientMenu-container {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  &__clientMenu-content {
    background: var(--background-grid);
    height: 47px;
    border-radius: 0 0 2px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__clientLogo {
    max-width: 30px;
    max-height: 25px;
  }

  &__clientName {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

.aside__selectOnLoginPage {
  //margin: 0 20px;
  width: calc(100%);
  // background-color: transparent !important;
  background: var(--background-sheet);
  // &.-disabled {
  //   opacity: 0.1;

  //   svg {
  //     path {
  //       fill: var(--color);
  //     }
  //   }
  // }

  &:focus {
    // background-color: transparent !important;
  }

  em {
    margin-left: 7px;
    color: var(--color);
    font-weight: var(--font-weight);
    font-size: 14px;
    font-style: normal;
  }
  //padding: 0 5px;
  &:before {
    content: none !important;
  }
  @media screen and (max-width: 980px) {
    margin-left: 0;
    padding-right: 0;
    width: 100%;
  }

  &__clientMenu-container {
    position: -webkit-sticky;
    position: sticky;
    bottom: 0;
  }

  &__clientMenu-content {
    background: var(--background-grid);
    height: 47px;
    border-radius: 0 0 2px 2px;
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__clientLogo {
    max-width: 30px;
    max-height: 25px;
  }

  &__clientName {
    max-width: 100px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    span {
      font-size: 14px;
      line-height: 17px;
    }
  }
}

#clients-menu {
  transform: translateX(6px);
}

.mainPopup {
  .popupContent {
    padding: 20px;
  }
}

.clients__popover {
  &__content {
    width: 345px;
    height: 87px;
    background: var(--background-header);
    border-radius: 2px;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 0 5px 15px;
  }

  &__headerText {
    font-size: 14px;
    line-height: 17px;
    color: #ffffff;
    margin-bottom: 10px;
  }

  &__menu {
    display: flex;
    justify-content: space-between;
  }

  &__button-content {
    display: flex;
    align-items: center;
    justify-content: space-around;
  }

  &__button-text {
    font-size: 12px;
    line-height: 15px;
    color: #eceff2;
  }

  &__select {
    border: 1px solid #596989;
    box-sizing: border-box;
    border-radius: 2px !important;
    width: 180px;

    fieldset {
      border: none;
    }
    svg {
      fill: #596989;
    }
  }

  &__selectOnLoginPage {
    border: 1px solid #485362;
    box-sizing: border-box;
    border-radius: 4px;
    // width: 180px;
    margin-bottom: 25px;
    height: 40px;
    padding: 0 10px;
  }

  &__button-icon {
    margin-top: 2px;
  }

  &__iconEdit {
    display: none;
    align-items: center;
    position: absolute;
    right: 7px;
    top: 50%;
    transform: translateY(-50%);

    svg {
      width: 15px;
      height: 15px;
    }
  }

  &__menuItem:hover {
    .clients__popover__iconEdit {
      display: flex;
    }
  }

  &__addCompany {
    display: flex;
    width: 398px;
    height: 208px;
    justify-content: space-between;
  }

  &__logoContainer {
    width: calc(33% - 10px);
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__formContainer {
    display: flex;
    flex-direction: column;
    width: calc(62%);
  }

  &__inputContainer:first-child {
    margin-bottom: 15px;
  }

  &__input {
    margin-top: 5px;
    background-color: var(--background-sheet);
    width: 250px;
    height: 30px;
    border-radius: 3px;
    font-size: 14px;
    line-height: 17px;
    font-weight: 400;
    text-transform: inherit;
    color: var(--color) !important;
    fieldset {
      border: none inherit;
    }
  }

  &__inputLabel {
    color: var(--color-noSelect);
    font-size: 12px;
    line-height: 15px;
    margin-left: 5px;
    font-weight: var(--font-weight);
  }

  &__addCompanyButtons {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    margin-top: 30px;
  }

  &__cancelButton {
    margin-left: 10px;
    color: var(--button-default-menu-text);
    background-color: inherit;
    border: 1px solid var(--background-grid) !important;

    &:hover {
      color: var(--button-hover-menu-text) !important;
      background-color: var(--background-grid) !important;
      border: 1px solid transparent !important;
    }
  }

  &__uploadField {
    background-color: var(--background-sheet);
    border-color: transparent !important;
    padding: 0 !important;
    margin-top: 12px;
    min-height: 112px !important;
    min-width: 112px !important;
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 112px !important;
    max-height: 112px !important;
    border-radius: 50%;
  }
}

.aside {
  &__wrapCollapseContainer {
    display: flex;
    position: absolute;
    bottom: 20px;
    transform: translateX(50%);
    overflow: hidden;
  }
  &__wrapCollapseContainerOpen {
    right: 20%;
  }
  &__counter {
    position: absolute;
    left: 55px;
    top: -8px;
    background: var(--phasePlan-possibleDriver);
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 14px;
    z-index: 1;
    color: #fff;

    @media screen and (max-width: 1200px) {
      display: none;

      .isMenuOpen & {
        display: flex;
      }
    }

    @media screen and (max-width: 767px) {
      width: 20px;
      height: 20px;
      left: 46px;
      top: -2px;
      font-size: 10px;
    }
  }
  &__wrapCollapseContainerClose {
    right: 50%;
  }
  &__wrapCollapseBtn {
    padding: 8px 17px;
    border-radius: 4px;
    border: var(--background-border);
    //background-color: #1d2230;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    cursor: pointer;
    user-select: none;

    @media screen and (max-width: 980px) {
      display: none;
    }

    img {
      overflow: hidden;
    }
  }
  &__openedMenu {
    transform: rotateY(0deg);
  }
  &__closedMenu {
    transform: rotateY(180deg);
  }
  &__innerTabItem {
    //@media screen and (max-width: 980px) {
    //  &:first-child {
    //    padding-left: 0 !important;
    //  }
    //}
  }
  &__listItemText {
    display: none;
    padding: 0 10px;
    span {
      letter-spacing: normal;
      font-size: 14px;
      font-family: var(--font-general);
      font-weight: var(--font-weight);
    }
  }

  &__wrapBtnRequest {
    display: flex;
    justify-content: center;
    padding: 9px 0;
    position: relative;
    width: 50px;
    height: 50px;
    z-index: 999;
  }
  &__btnRequest {
    display: flex;
    align-items: center;
    border-radius: 25px;
    background-color: var(--button-default-menu);
    width: 50px;
    height: 50px;
    justify-content: center;
    cursor: pointer;
    position: fixed;
    left: 15px;
    z-index: 15;
    color: var(--color);
    font-size: 15px;

    &.-disabled {
      cursor: default;
      background-color: var(--background-grid);

      &:before,
      &:after {
        background-color: var(--color1);
      }

      &:hover {
        width: 50px;
        background-color: var(--background-grid);
        span {
          display: block;
          opacity: 0;
        }
        &:before,
        &:after {
          background-color: var(--color1);
        }
      }
    }

    @media screen and (max-width: 1023px) {
      position: absolute;
    }

    @media screen and (max-width: 767px) {
      width: 35px;
      height: 35px;
    }

    &:hover {
      width: 137px;
      background-color: var(--color2);
      span {
        display: block;
        opacity: 1;
      }
      &:before,
      &:after {
        background-color: var(--button2-hover-text);
      }
    }

    span {
      padding-left: 13px;
      color: var(--button2-hover-text);
      display: none;
      transition: 0.2s linear;
      opacity: 0;
      font-size: 14px;
      font-family: var(--font-general);

      @media screen and (max-width: 767px) {
        padding-left: 5px;
      }
    }
    &:before,
    &:after {
      content: '';
      position: absolute;
      top: 50%;
      left: 25px;
      background-color: var(--color-secondary);
      transform: translate(-50%, -50%);

      @media screen and (max-width: 767px) {
        left: 18px;
      }
    }

    &:before {
      width: 2px;
      height: 25px;

      @media screen and (max-width: 767px) {
        height: 18px;
      }
    }

    &:after {
      height: 2px;
      width: 25px;

      @media screen and (max-width: 767px) {
        width: 18px;
      }
    }
  }
  &__btnRequestOpened {
    width: 95%;
    border-radius: 4px;

    &:before,
    &:after {
      left: 25px;
    }
  }
}
.newsContentSmallText {
  font-size: 12px;
}
.linearProgressAside {
  position: fixed;
  width: 100vw;
  top: 60px;
  background: #f3a359;
  height: 2px;
  z-index: 1200;
}

.b-domains {
  display: flex;
  background-color: var(--background-sheet);

  .item {
    cursor: pointer;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    padding: 10px;
    opacity: 0.5;
    align-items: center;
    min-width: 60px;

    &.-active {
      opacity: 1;
    }

    &:hover {
      background: var(--button-default-hover);
      opacity: 1;
    }

    svg {
      width: 30px;
      height: 30px;
    }
  }
}
