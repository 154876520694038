.container {
  display: flex;
  width: 100%;
  border: 1px solid transparent;
}

.select {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: #ffffff;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;

  &:focus-visible {
    outline: var(--color) auto 1px;
  }
}

.title {
  color: #838ea2;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
}

.text {
  color: #38455e;
  font-weight: 400;
  font-size: 14px;
  display: flex;
}

.icon {
  cursor: pointer;
}

.item {
  padding: 5px;
  display: flex;
  align-items: center;
  cursor: pointer;
  width: 100%;
  background: transparent;

  &:focus-visible {
    outline: var(--color) auto 1px;
  }

  &:hover {
    background: #e2e7ec;
  }

  button {
  }
}

.disabled {
  opacity: 0.5;
}

.error {
  border: 1px solid red;

  .title,
  .text {
    color: red;
  }
}
