.levelLabels {
  foreignObject {
    height: 25px;
  }
}

.b-levelLabelsAlign {
  position: fixed;
  display: flex;
  justify-content: flex-end;
}

.b-levelLabels {
  width: max-content;
  height: 15px;
  font-size: 12px;
  background: #f1f4f8;
  border: 1px solid #5e739e;
  padding: 3px 10px;
}
