@import '../../../../designTokens/scss/_variablesDesignTokes';

.b-placeholder-dark,
.b-placeholder-light {
  &_company {
    display: flex;
    align-items: flex-start;
    gap: $ref-spacing-0;
    flex-shrink: 0;
    box-sizing: border-box;

    &_24 {
      width: $ref-sizing-24;
      height: $ref-sizing-24;
      border-radius: $border-radius-12;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_no_company_img {
        padding: $ref-spacing-4;

        svg {
          width: 16px;
          height: 16px;
          flex-shrink: 0;
        }
      }
    }
    &_32 {
      width: $ref-sizing-32;
      height: $ref-sizing-32;
      border-radius: $border-radius-16;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_no_company_img {
        padding: $ref-spacing-6;

        svg {
          width: 20px;
          height: 20px;
          flex-shrink: 0;
        }
      }
    }
    &_48 {
      width: $ref-sizing-48;
      height: $ref-sizing-48;
      border-radius: $border-radius-24;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_no_company_img {
        padding: $ref-spacing-6;

        svg {
          width: 36px;
          height: 36px;
          flex-shrink: 0;
        }
      }
    }
    &_56 {
      width: $ref-sizing-56;
      height: $ref-sizing-56;
      border-radius: $border-radius-100;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_no_company_img {
        padding: $ref-spacing-8;

        svg {
          width: 40px;
          height: 40px;
          flex-shrink: 0;
        }
      }
    }
    &_120 {
      width: $ref-sizing-120;
      height: $ref-sizing-120;
      border-radius: $border-radius-100;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_no_company_img {
        padding: $ref-spacing-16;

        svg {
          width: 88px;
          height: 88px;
          flex-shrink: 0;
        }
      }
    }
    &_144 {
      width: $ref-sizing-144;
      height: $ref-sizing-144;
      border-radius: $border-radius-100;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_no_company_img {
        padding: $ref-spacing-20;

        svg {
          width: 104px;
          height: 104px;
          flex-shrink: 0;
        }
      }
    }
  }

  &_persona {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    box-sizing: border-box;

    &_24 {
      width: $ref-sizing-24;
      height: $ref-sizing-24;
      border-radius: $border-radius-12;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_label_24 {
        font-family: $typography-label-sm-font-family;
        font-weight: $typography-label-sm-font-weight;
        line-height: $typography-label-sm-line-height;
        font-size: $typography-label-sm-font-size;
        letter-spacing: $typography-label-sm-letter-spacing;
      }
    }
    &_32 {
      width: $ref-sizing-32;
      height: $ref-sizing-32;
      border-radius: $border-radius-16;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_label_32 {
        font-family: $typography-label-lg-font-family;
        font-weight: $typography-label-lg-font-weight;
        line-height: $typography-label-lg-line-height;
        font-size: $typography-label-lg-font-size;
        letter-spacing: $typography-label-lg-letter-spacing;
      }
    }
    &_48 {
      width: $ref-sizing-48;
      height: $ref-sizing-48;
      border-radius: $border-radius-24;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_label_48 {
        line-height: $typography-title-lg-line-height;
        font-weight: $typography-title-lg-font-weight;
        font-family: $typography-title-lg-font-family;
        font-size: $typography-title-lg-font-size;
        letter-spacing: $typography-title-lg-letter-spacing;
      }
    }
    &_56 {
      width: $ref-sizing-56;
      height: $ref-sizing-56;
      border-radius: $border-radius-100;

      &_img_center {
        background-position: center !important;
        background-size: cover !important;
        background-repeat: no-repeat !important;
      }

      &_label_56 {
        line-height: $typography-title-lg-line-height;
        font-weight: $typography-title-lg-font-weight;
        font-family: $typography-title-lg-font-family;
        font-size: $typography-title-lg-font-size;
        letter-spacing: $typography-title-lg-letter-spacing;
      }
    }
    &_120 {
      width: $ref-sizing-120;
      height: $ref-sizing-120;
      border-radius: $border-radius-100;
    }

    &_img_center {
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }

    &_label_120 {
      line-height: $typography-display-md-line-height;
      font-weight: $typography-display-md-font-weight;
      font-family: $typography-display-md-font-family;
      font-size: $typography-display-md-font-size;
      letter-spacing: $typography-display-md-letter-spacing;
    }
  }
  &_144 {
    width: $ref-sizing-144;
    height: $ref-sizing-144;
    border-radius: $border-radius-100;

    &_img_center {
      background-position: center !important;
      background-size: cover !important;
      background-repeat: no-repeat !important;
    }

    &_label_144 {
      line-height: $typography-display-lg-line-height;
      font-weight: $typography-display-lg-font-weight;
      font-family: $typography-display-lg-font-family;
      font-size: $typography-display-lg-font-size;
      letter-spacing: $typography-display-lg-letter-spacing;
    }
  }
}

.b-placeholder-dark {
  &_company {
    background-color: $dark-on-bgd-srf-5;
    &_24,
    &_32,
    &_48,
    &_56,
    &_120,
    &_144 {
      &_no_company_img {
        svg {
          circle {
            stroke: $dark-on-bgd-srf-4;
          }
          path {
            stroke: $dark-on-bgd-srf-4;
          }
        }
      }
    }
  }
  &_persona {
    background-color: $dark-secondary;
    &_24,
    &_32,
    &_48,
    &_56,
    &_120,
    &_144 {
      &_label_24,
      &_label_32,
      &_label_48,
      &_label_56,
      &_label_120,
      &_label_144 {
        color: $dark-on-secondary;
      }
    }
  }
}
.b-placeholder-light {
  &_company {
    background-color: $light-on-bgd-srf-5;
    &_24,
    &_32,
    &_48,
    &_56,
    &_120,
    &_144 {
      &_no_company_img {
        svg {
          circle {
            stroke: $light-on-bgd-srf-4;
          }
          path {
            stroke: $light-on-bgd-srf-4;
          }
        }
      }
    }
  }
  &_persona {
    background-color: $light-secondary;
    &_24,
    &_32,
    &_48,
    &_56,
    &_120,
    &_144 {
      &_label_24,
      &_label_32,
      &_label_48,
      &_label_56,
      &_label_120,
      &_label_144 {
        color: $light-on-secondary;
      }
    }
  }
}
