.scheduleTitle {
  foreignObject {
    width: 140px;
    height: 25px;
  }
}

.b-scheduleTitle {
  //position: relative;
  position: fixed;
  width: 120px;
  height: 15px;
  font-size: 12px;
  background: #d2d7df;
  // border-radius: 2px;
  padding: 4px;

  &-container {
    padding: 0;
    // padding: 0 10px 10px 10px;
  }

  &-header {
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;
    height: 15px;

    .mainTitle,
    .groupTitle {
      font-size: 12px;
      line-height: 17px;
      margin-right: 15px;
    }

    .groupTitle {
      color: var(--color-secondary);
      font-weight: 400;
    }
  }

  // &-content {
  //   margin-top: 12px;
  //   height: 100%;
  //   width: 100%;
  //   display: flex;
  //   position: relative;

  //   .nfStatistic {
  //     cursor: default;
  //     margin-top: 10px;
  //     display: flex;
  //     align-items: flex-start;
  //     flex-direction: column;

  //     &-item {
  //       margin-bottom: 7px;

  //       span {
  //         color: var(--color-secondary);
  //       }
  //     }
  //   }

  //   .nfGroupStatistic {
  //     margin-top: 10px;
  //     display: flex;
  //     align-items: flex-start;
  //     flex-direction: column;
  //     overflow-y: auto;
  //     overflow-x: hidden;
  //     height: 143px;
  //     white-space: nowrap;
  //     text-overflow: ellipsis;

  //     .no-options {
  //       color: var(--color-secondary);
  //     }

  //     &-item {
  //       cursor: default;
  //       margin-bottom: 7px;
  //       display: flex;
  //       align-items: center;
  //       width: 90%;

  //       .label  {
  //         max-width: 70%;
  //         overflow: hidden;
  //         white-space: nowrap;
  //         text-overflow: ellipsis;
  //         color: var(--color-secondary);
  //       }

  //       .labelCount {
  //         margin-left: 5px;
  //         max-width: 30%;
  //         color: var(--color-secondary);
  //       }

  //       .colorIndicator {
  //         margin-right: 7px;
  //         width: 25px;
  //         min-width: 25px;
  //         height: 13px;
  //         border-radius: 9px;
  //       }
  //     }
  //   }

  //   .right {
  //     margin-left: 10px;
  //     display: flex;
  //     align-items: center;
  //     justify-content: center;
  //     flex-direction: column;
  //     width: 40%;
  //     min-width: 40%;
  //   }

  //   .left {
  //     width: 60%;
  //     min-width: 60%;
  //   }
  // }
}

.b-stoneText {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  span {
    font-size: 12px;
  }
}

.closeSvg {
  position: absolute;
  right: 5px;
  top: 3px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  // cursor: pointer;
  svg {
    width: 10px;
    height: 10px;
    // cursor: pointer;

    path {
      fill: var(--color-secondary);
    }
  }
}
