.App {
  text-align: center;
  background-color: white;
  box-sizing: border-box;
  /* background-color: #0c1627; */
}

.linearProgress {
  position: fixed;
  top: 57px;
  z-index: 1200;
  height: 2px;
  left: 0;
  width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* custom CSS */

text {
  font-family: 'Proxima Nova';
}

.chartTitle {
  font-family: 'Proxima Nova';
  fill: #38455e;
  /* fill: white; */
  font-size: 16px;
}

.marker text {
  font-size: 12px;
}

.levelLabel {
  /* fill: #AAA; */
  fill: black;
  font-size: 12px;
}

.domain {
  /* stroke: #AAA; */
  stroke: none;
}

.tick line {
  /* stroke: #AAA; */
  stroke: rgb(80, 80, 80);
  stroke-width: 0.5;
}
.year-line {
  stroke: #38455e !important;
  stroke-width: 1.5px !important;
}

.year-line-new {
  stroke: #38455e !important;
  stroke-width: 1.5px !important;
}
.current-year-g {
  foreignObject {
    height: 15px;
  }
}
.current-year-g-new {
  foreignObject {
    height: 12px;
  }
}
.current-year-box {
  position: fixed;
  width: 40px;
}

.current-year-box-new {
  position: fixed;
  width: 50px;
}

.current-year {
  height: 15px;
  font-size: 12px;
  font-weight: 700;
  background: white;
  box-shadow: inset 10px 0 7px -5px rgba(144, 155, 176, 0.4);
  padding-left: 5px;

  @media screen and (max-width: 1200px) {
    box-shadow: none;
    padding: 0;
  }
}

.current-year-new {
  height: 12px;
  font-size: 12px;
  font-weight: 700;
  background: white;
}

.year-line {
  stroke: #38455e !important;
  stroke-width: 1.5px !important;
}
.current-year-g {
  foreignObject {
    height: 15px;
  }
}
.current-year-box {
  position: fixed;
  width: 40px;
}

.current-year {
  height: 15px;
  font-size: 12px;
  font-weight: 700;
  background: white;
  box-shadow: inset 10px 0 7px -5px rgba(144, 155, 176, 0.4);
  padding-left: 5px;

  @media screen and (max-width: 1200px) {
    box-shadow: none;
    padding: 0;
  }
}

.tick text {
  font-size: 11px;
}

.separatorLine {
  /* stroke: #AAA; */
  stroke: black;
  stroke-width: 0.2;
}

.levelBoxBg {
  fill: none;
}

.constructionBorder {
  stroke-dasharray: 10, 15;
  stroke-linecap: round;

  &.-solid {
    stroke-dasharray: none;
  }
}

.button text {
  font-size: 12px;
  fill: white;
}

.button rect {
  fill: #596989;
  stroke: none;
}

/*   .button :hover{
    fill: #f8b362;
  } */
.button :hover {
  fill: #8ba1b6;
}

.subToggle :hover {
  fill: #8ba1b6;
}

.toggle_active {
  fill: #596989;
  stroke: #596989;
  stroke-width: 1;
}

.toggle_inactive {
  fill: #0c1627;
}
.toolbarButtonMobile {
  color: #909bb0;
  transition: background-color 200ms ease-in-out;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  &-text {
    display: none;
    font-family: Proxima Nova;
    font-size: 14px;
  }

  &.-active {
    color: #596989;
    background-color: #e2e7ec;
    width: 100% !important;

    .toolbarButton-text {
      display: none !important;
    }
  }

  &.-disabled {
    opacity: 0.4;
    pointer-events: none;
  }

  @media screen and (max-height: 450px) {
    height: 35px;
  }
}

.toolbarButton {
  color: #909bb0;
  transition: background-color 200ms ease-in-out;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;

  .icon {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &:hover {
    color: #38455e;
    background-color: #d2d7df;
    position: relative;
    width: 220px;
    cursor: pointer;

    .toolbarButton-text {
      display: flex;
      position: absolute;
      left: 140px;
      font-weight: var(--font-weight);
    }

    svg {
      path {
        fill: inherit;
      }
    }
  }

  &-text {
    display: none;
    font-family: Proxima Nova;
    font-size: 14px;
  }

  &.-active {
    color: #596989;
    background-color: #e2e7ec;
    width: 100% !important;

    .toolbarButton-text {
      display: none !important;
    }
  }

  &.-disabled {
    opacity: 0.4;
    pointer-events: none;
  }
}

.groupBtns {
  background-color: white;
}

.sideToolbar {
  background-color: white;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  gap: 0;
  min-width: 80px;
  max-width: 80px;
  /* border-left: solid 1px #909bb0; */
  box-shadow: 0 0 10px 5px rgba(144, 155, 176, 0.4);
  height: calc(100vh - 61px);
  box-sizing: border-box;
  padding: 0;

  @media screen and (max-height: 450px) {
    min-width: 60px;
    max-width: 60px;
  }

  @media screen and (max-width: 824px) {
    min-width: 60px;
    max-width: 60px;
  }

  svg {
    path {
      fill: var(--navIcon-default);
    }
  }

  @media screen and (max-width: 1200px) {
    position: fixed;
    top: 0;
    bottom: 0;
    transform: translate(-80px, 60px);
    transition: transform 0.3s;
    box-shadow: 0 10px 10px 5px rgba(144, 155, 176, 0.4);

    .isMenuOpen & {
      transform: translate(0px, 60px);
      overflow: visible;
    }
  }

  &.--disabled {
    pointer-events: none;
  }
}

.scheduleBg {
  fill: white;
  /* fill: #0c1627; */
  fill-opacity: 0.7;
}

.legendLabels {
  fill: black;
}

.legendValues {
  fill: black;
}

.tooltipText {
  fill: rgb(0, 0, 0);
  font-size: 10px;
}

.settingsCheckBox {
  padding: 8px;

  @media screen and (max-width: 800px) {
    padding: 2px;
  }

  svg {
    width: 20px;
    height: 20px;

    path {
      fill: var(--background-checkbox);
    }
  }
}

.selectContainer {
  height: 40px;
  width: 175px;
  position: relative;

  @media screen and (max-width: 800px) {
    width: 165px;
  }

  &.-filterSelect {
    .settingsSelect__placeholder {
      left: 23px;

      &.-selected {
        transform: translate(0, -10px);
      }
    }
  }
}

.settingsSelect {
  height: 20px;
  width: 100px;
  background-color: var(--background-main);
  border: 1px solid inherit;
  border-radius: 4px;

  #mui-component-select-multi-level-nfs {
    padding: 16.5px 3px;
  }

  &.-groupSelect {
    width: inherit;
    height: inherit;

    em {
      position: absolute;
      left: 9px;
      bottom: 7px;
      color: var(--color);
      font-size: 14px;
      line-height: 17px;
    }

    svg {
      transform: none;
    }
  }

  &.-filterSelect {
    border: 1px solid var(--color-noSelect);
    width: inherit;
    height: inherit;

    em {
      position: absolute;
      left: 9px;
      bottom: 7px;
      color: var(--color);
      font-size: 14px;
      line-height: 17px;
    }

    svg {
      transform: none;
    }

    .settingsSelect__renderValue {
      left: 22px;
    }
  }

  &__clearIcon {
    width: 10px;
    height: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: var(--background-header);
    position: absolute;
    left: 5px;
    top: 35%;
    z-index: 2;

    svg {
      path {
        fill: var(--color-secondary);
      }
    }

    &:hover {
      svg {
        path {
          fill: #f3a359;
        }
      }
    }
  }

  &__headerContent {
    display: flex;
    justify-content: space-between;
    position: sticky;
    z-index: 2;
    background-color: inherit;
    padding: 8px 0;

    &.-ufMfSelect {
      top: 40px;
    }

    .btnClear {
      text-decoration: underline;
      cursor: pointer;
      margin: 10px 12px;
      color: var(--background-multiSelect-text);
      font-size: 12px;
      font-weight: var(--font-weight);

      &:hover {
        color: var(--background-multiSelect-hover-text);
      }
    }
  }

  &__renderValue {
    width: 65%;
    position: absolute;
    left: 13px;
    bottom: 7px;
    font-size: 12px;
    line-height: 14px;
    color: var(--color);
    text-overflow: ellipsis;
    overflow: hidden;
    text-align: start;
  }

  &__customIcon {
    cursor: pointer;
    position: absolute;
    right: 9px;
    bottom: 11px;
    z-index: 2;
  }

  &__placeholder {
    cursor: pointer;
    position: absolute;
    left: 9px;
    bottom: 13px;
    color: var(--color);
    font-size: 14px;
    line-height: 17px;
    z-index: 2;

    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    max-width: 128px;

    &.-selected {
      transform: translate(4px, -10px);
      font-size: 12px;
      line-height: 14px;
      color: var(--color-secondary);
    }
  }

  #mui-component-select-flowline_style {
    padding: 0 32px 0 3px;
  }

  fieldset {
    border: none;
  }

  &.-disabled {
    opacity: 0.1;

    svg {
      path {
        fill: var(--color);
      }
    }
  }

  &:focus {
    background-color: transparent !important;
  }

  em {
    font-family: var(--font-general) !important;
    margin-left: 5px;
    color: var(--color-secondary);
    font-weight: normal;
    font-size: 12px;
    line-height: 14px;
    font-style: normal;
  }

  //padding: 0 5px;
  &:before {
    content: none !important;
  }
}

.settingsInput {
  height: 20px;
  width: 65px;
  background-color: var(--background-main);
  border: 1px solid inherit !important;
  border-radius: 4px;

  @media screen and (max-width: 800px) {
    width: 85px;
  }

  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none; // Yeah, yeah everybody write about it
  }

  input[type='number'],
  input[type='number']:hover,
  input[type='number']:focus {
    appearance: none;
    -moz-appearance: textfield;
  }

  &.-styledArrows {
    input {
      padding: 0;
    }
  }

  input {
    font-size: 12px;
    font-family: var(--font-general) !important;
    color: var(--color-secondary);
    font-weight: normal;
    text-align: center;
    padding: 0 23px 0 10px;

    &::placeholder {
      color: var(--color-noSelect);
      font-weight: normal;
      opacity: 1 !important;
    }
  }

  .startAdornment,
  .endAdornment {
    font-size: 12px;
    font-family: var(--font-general) !important;
    color: var(--color-secondary);
    font-weight: normal;
  }

  .endAdornment {
    position: absolute;
    right: 7px;
  }
}

.incDecButtons {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-right: 2px;

  &__button {
    width: 13px;
    height: 9px;
    background: var(--background-header);
    border-radius: 3px;
    display: flex;
    align-items: center;
    justify-content: center;

    &:first-child {
      margin-bottom: 1px;
    }

    span {
      font-size: 10px;
      color: var(--text-scondary);
    }
  }

  @media screen and (max-width: 800px) {
    flex-direction: row;

    &__button {
      width: 25px;
      height: 19px;

      &:first-child {
        margin-right: 3px;
        margin-bottom: 0;
      }
    }
  }
}

.search {
  padding: 0 5px 0 25px;
  margin: 0 10px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  background-color: var(--background-multiSelectSearch);
  height: 24px;
  border-radius: 4px;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  max-width: 174px;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  color: var(--color);

  @media screen and (max-width: 1024px) {
    max-width: 140px;
  }

  &__wrap {
    transform: translateY(-8px);
    top: 8px;
    position: sticky;
    z-index: 2;
    padding: 8px 0;
    background-color: var(--background-select);
  }

  input {
    color: var(--color);
    &::placeholder {
      font-size: 12px;
      font-weight: var(--font-weight);
    }
  }

  svg {
    width: 10px;
    height: 10px;
    position: absolute;
    left: 18px;
  }
}

.toolbox {
  box-shadow: -5px 5px 10px 5px rgba(144, 155, 176, 0.4);

  &__header {
    height: 33px;
    background-color: var(--background-header);
    display: flex;
    align-items: center;
    justify-content: center;

    &.-graphSettings {
      width: 420px;

      @media screen and (max-width: 800px) {
        width: 363px;
      }
    }

    &.-displaySettings {
      width: 740px;
      @media screen and (max-width: 800px) {
        width: 350px;
      }
    }
  }

  &__headerText {
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: var(--color);
  }

  &__resetButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: inherit;
    position: absolute;
    right: 10px;

    span {
      cursor: pointer;
      font-weight: 400;
      font-size: 12px;
      line-height: 14px;
      color: var(--color-secondary);

      &:hover {
        text-decoration: underline;
      }
    }

    &.deliverablesClear {
      display: block;
      height: auto;
      position: inherit;
      align-self: end;
    }
  }

  &__settings {
    background-color: var(--background-sheet);
    padding: 20px;
    display: flex;

    &.-graphSettings {
      width: 380px;
      height: 330px;

      @media screen and (max-width: 800px) {
        width: 353px;
        padding: 5px;
      }
    }

    @media screen and (max-width: 800px) {
      flex-direction: row;
      flex-wrap: wrap;
      padding: 5px;
    }

    &.-displaySettings {
      width: 700px;
      height: 271px;

      @media screen and (max-width: 800px) {
        width: 340px;
        height: 325px;
      }
    }

    .displaySettings {
      display: flex;
      flex-direction: column;
      border-right: 1px solid var(--color-noSelect);
    }
    .advancedDisplaySettings {
      display: flex;
      flex-direction: column;
      border-right: 1px solid var(--color-noSelect);

      @media screen and (max-width: 800px) {
        border-right: none;
      }
    }

    .displaySettings {
      width: 25%;
      justify-content: center;

      @media screen and (max-width: 800px) {
        width: 115px;
        justify-content: start;
      }
    }

    .advancedDisplaySettings {
      width: 37.5%;
      margin-left: 15px;
      justify-content: flex-start;
      align-items: flex-start;

      &:last-child {
        border-right: none;
      }

      @media screen and (max-width: 800px) {
        width: 220px;
        margin-left: 4px;

        &:last-child {
          border-top: 1px solid var(--color-noSelect);
          width: 100%;
          margin-top: 5px;
          padding-top: 5px;
          padding-bottom: 5px;
        }
      }
    }

    .settingsItem {
      display: flex;
      justify-content: flex-start;
      align-items: center;
    }

    .inputSettingsItem {
      width: 90%;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.-checkbox {
        padding: 0;

        &:first-child {
          padding: 0;
          margin-top: -2px;
        }
      }

      padding: 0 0 12px 11px;

      &:first-child {
        padding: 5px 0 15px 11px;
      }

      @media screen and (max-width: 800px) {
        padding: 0 0 7px 6px;

        &:first-child {
          padding: 2px 0 7px 6px;
        }
      }
    }

    .settingsLabel {
      font-size: 14px;
      line-height: 17px;
      color: var(--color);
    }

    .settingsSubHeader {
      font-size: 12px;
      line-height: 14px;
      color: var(--color-secondary);
    }

    .filtersPart {
      width: 50%;
      margin-left: 10px;
      display: flex;
      flex-direction: column;
      align-items: center;

      @media screen and (max-width: 800px) {
        width: 175px;
        margin-left: 0;
      }

      &__addedFiltersContainer {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 10px;
        overflow-y: auto;
        height: 100%;
        width: 100%;

        .filterItem {
          margin-top: 10px;
          width: 100%;

          &.deliverablesItem {
            margin-right: 0 !important;
          }
        }

        &.-scroll {
          margin-right: -18px;

          .filterItem {
            margin-right: 10px;
          }
        }

        &.deliverablesScroll {
          height: 100%;
          // overflow-y: auto;
          // margin-right: 0;
          height: 12rem;
          max-height: 73%;
        }
      }

      &.deliverablesContainer {
        width: auto;
        // overflow-y: auto;
        // overflow-x: hidden;
        margin: 7px 10px 0 0;
        padding-right: 10px;
        height: 100%;
      }
    }

    .groupPart {
      display: flex;
      flex-direction: column;
      width: 50%;
      border-right: 1px solid var(--color-noSelect);

      @media screen and (max-width: 800px) {
        width: 175px;
      }

      &__colorScheme {
        margin-top: 10px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .colorType {
          margin: 5px 0 0 5px;
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          overflow: auto;
          height: 265px;

          &__item {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: flex-start;
            margin: 5px 10px 0 0;

            .palette {
              width: 17px;
              height: 17px;
              border-radius: 3px;
              margin-right: 10px;
              display: flex;
              justify-content: center;
              align-items: center;

              svg {
                width: 11px;
                height: 12px;
              }
            }

            span {
              width: 130px;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: 14px;
              line-height: 17px;
              color: var(--color-secondary);
            }
          }
        }

        &.deliverablesScheme {
          // overflow: hidden;
          height: 100%;
        }
      }
    }
  }
}

.overdueDateContainer {
  font-size: 10px;
  background-color: white;

  border: 1px solid #ed5147;
  border-radius: 4px;

  width: 56px;
  height: 23px;

  span {
    color: #ed5147;
  }
}
