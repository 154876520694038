.svg-text-wrap {
  display: flex;
  justify-content: flex-start;
  white-space: nowrap;
  span {
    font-size: 12px;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
