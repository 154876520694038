.container {
  display: flex;
  width: 100%;
  border-radius: 3px;
  border: 1px solid transparent;
}

.calendar {
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  background: #ffffff;
  height: 40px;
  padding: 0 10px;
  cursor: pointer;
}

.title {
  color: #838ea2;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  display: flex;
}

.text {
  color: #38455e;
  font-weight: 400;
  font-size: 14px;
  display: flex;

  input {
    font-size: 14px;
    font-family: var(--font-general);
    color: #38455e;
    border: none;

    &:focus-visible {
      outline: none;
    }
  }
}

.icon {
  cursor: pointer;
  background: transparent;

  &:focus-visible {
    outline: var(--color) auto 1px;
  }
}

.error {
  border: 1px solid red;

  .title {
    color: red;
  }
  .text {
    color: red;
  }
}
