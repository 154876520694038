@import '../../../../designTokens/scss/variablesDesignTokes';

.b-authorization-loginPage-dark,
.b-authorization-loginPage-light {
  &_container {
    height: 100vh;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    align-items: center;
    gap: $ref-sizing-20;

    @media screen and (max-width: 639px), (max-height: 639px) {
      position: relative;
      justify-content: center;
    }
  }

  &_okta {
    width: 100%;
    margin-top: $ref-sizing-20;
  }

  &_topInfo {
    min-width: 370px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    margin-top: $ref-sizing-200;

    .left_svg {
      width: 80px;
      height: 80px;
    }
    .right_svg {
      width: 200px;
      height: 80px;
      path {
        fill: #fff;
      }
    }

    &.-logo {
      @media screen and (max-width: 639px), (max-height: 639px) {
        gap: $ref-sizing-60 !important;
      }
    }

    @media screen and (max-width: 1440px) {
      margin-top: $ref-sizing-80;
    }

    @media screen and (max-width: 1024px) {
      margin-top: $ref-sizing-42;
    }

    @media screen and (max-width: 767px) {
      min-width: 350px;
      margin-left: 0;
    }

    @media screen and (max-width: 639px), (max-height: 639px) {
      margin-top: $ref-spacing-0;
      gap: $ref-sizing-24;
    }

    @media screen and (max-height: 639px) {
      flex-direction: row;
      gap: $ref-sizing-60;
    }
  }

  &_bottomInfo {
    display: flex;
    margin-bottom: 100px;

    @media screen and (max-width: 1440px) {
      margin-bottom: $ref-sizing-80;
    }

    @media screen and (max-width: 1024px) {
      margin-bottom: $ref-sizing-20;
    }

    @media screen and (max-width: 639px), (max-height: 639px) {
      position: absolute;
      bottom: 0;
      margin-bottom: $ref-sizing-16;
    }

    @media screen and (max-height: 639px) {
      left: calc(50% - 235px);
    }
  }

  &_headRow,
  &_companyRow {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &_companyRow {
    margin-bottom: $ref-sizing-16;

    &_left {
      width: calc(100% - 64px);
      display: flex;
      align-items: center;
      gap: $ref-sizing-8;
    }
  }

  &_menuItemClients {
    &_edit {
      display: none;
    }

    &:hover &_edit {
      display: flex;
    }
  }

  &_menuItem {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &_archivedTitle {
      opacity: 0.5;
      &.-whiteSpace {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    font-family: $typography-body-md-font-family;
    font-weight: $typography-body-md-font-weight;
    line-height: $typography-body-md-line-height;
    font-size: $typography-body-md-font-size;
    letter-spacing: $typography-body-md-letter-spacing;

    p {
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &_inviteBtn {
    margin-top: 30px;
  }

  &_logoContainer {
    margin-bottom: 40px;
    height: 74px;

    @media screen and (max-width: 639px), (max-height: 639px) {
      margin-bottom: $ref-spacing-0;
    }

    @media screen and (max-height: 639px) {
      align-self: flex-start;
    }

    &__header {
      text-align: center;
      margin-top: 14px;
    }
  }

  &_form {
    width: Min(370px, 100%);
    padding: $ref-spacing-32;
    box-sizing: border-box;
    &.-logo {
      gap: $ref-spacing-8;
      display: flex;
      flex-direction: column;
    }

    //@media screen and (max-width: 767px) {
    //  width: Min(320px, 100%);
    //}

    @media screen and (max-width: 639px), (max-height: 639px) {
      background: none !important;
      box-shadow: none !important;
      padding: $ref-spacing-0 !important;
    }

    @media screen and (max-height: 639px) {
      min-width: 320px !important;
    }

    &__header {
      margin-bottom: 10px;
    }
  }

  &_signedInContainer {
    margin-bottom: 40px;

    @media screen and (max-width: 639px), (max-height: 639px) {
      margin-bottom: $ref-sizing-32;
    }

    &_title {
      font-family: $typography-headline-md-font-family;
      font-weight: $typography-headline-md-font-weight;
      line-height: $typography-headline-md-line-height;
      font-size: $typography-headline-md-font-size;
      letter-spacing: $typography-headline-md-letter-spacing;
      margin-bottom: 5px;

      @media screen and (max-width: 639px), (max-height: 639px) {
        font-family: $typography-headline-sm-font-family;
        font-weight: $typography-headline-sm-font-weight;
        line-height: $typography-headline-sm-line-height;
        font-size: $typography-headline-sm-font-size;
        letter-spacing: $typography-headline-sm-letter-spacing;
      }
    }

    &_subTitle {
      font-family: $typography-body-sm-font-family;
      font-weight: $typography-body-sm-font-weight;
      line-height: $typography-body-sm-line-height;
      font-size: $typography-body-sm-font-size;
      letter-spacing: $typography-body-sm-letter-spacing;

      @media screen and (max-width: 639px), (max-height: 639px) {
        font-family: $typography-body-md-font-family;
        font-weight: $typography-body-md-font-weight;
        line-height: $typography-body-md-line-height;
        font-size: $typography-body-md-font-size;
        letter-spacing: $typography-body-md-letter-spacing;
      }
    }
  }

  &_passwordBlock {
    position: relative;
  }

  &_forgotPassword {
    display: flex;
    justify-content: flex-end;

    @media screen and (max-width: 639px), (max-height: 639px) {
      justify-content: center;

      a {
        width: 100%;

        .tooltipBasis {
          display: block;
          width: 100%;

          .b-button-dark,
          .b-button-light {
            width: 100%;
          }
        }
      }
    }

    a {
      font-size: $typography-body-sm-font-size;
      line-height: $typography-body-sm-line-height;
      //font-weight: $typography-body-sm-font-weight;
      font-weight: 400;
    }
  }

  &_loginBtn {
    .b-button-dark,
    .b-button-light {
      width: 100%;
      margin-top: $ref-sizing-24;

      @media screen and (max-width: 639px), (max-height: 639px) {
        margin-top: $ref-sizing-32;
        margin-bottom: $ref-sizing-16;
      }
    }
  }

  &_signOut {
    display: flex;
    justify-content: flex-end;
    cursor: pointer;
    font-size: $typography-body-sm-font-size;
    line-height: $typography-body-sm-line-height;
    font-weight: 400;

    @media screen and (max-width: 639px), (max-height: 639px) {
      justify-content: center;

      .tooltipBasis {
        display: block;
        width: 100%;

        .b-button-dark,
        .b-button-light {
          width: 100%;
        }
      }
    }
  }

  &_goBackWidget {
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: $typography-body-sm-font-size;
    line-height: $typography-body-sm-line-height;
    font-weight: 400;

    @media screen and (max-width: 639px), (max-height: 639px) {
      justify-content: center;

      .tooltipBasis {
        display: block;
        width: 100%;

        .b-button-dark,
        .b-button-light {
          width: 100%;
        }
      }
    }
  }

  &_loginError {
    display: flex;
    justify-content: center;
    margin-bottom: $ref-spacing-6;
    font-size: $typography-label-lg-font-size;
    line-height: $typography-label-lg-line-height;
    font-weight: $typography-label-lg-font-weight;
    color: $ref-system-red-200;
  }

  &_textLabel {
    font-family: $typography-body-md-font-family;
    font-weight: $typography-body-md-font-weight;
    line-height: $typography-body-md-line-height;
    font-size: $typography-body-md-font-size;
    letter-spacing: $typography-body-md-letter-spacing;

    @media screen and (max-width: 639px), (max-height: 639px) {
      font-family: $typography-body-lg-font-family;
      font-weight: $typography-body-lg-font-weight;
      line-height: $typography-body-lg-line-height;
      font-size: $typography-body-lg-font-size;
      letter-spacing: $typography-body-lg-letter-spacing;
    }
  }

  &_clientsList {
    max-height: 330px;
    overflow: auto;
    margin-top: $ref-spacing-8;
    margin-bottom: $ref-spacing-8;
    padding: $ref-spacing-8;
    box-sizing: border-box;
    gap: $ref-spacing-4;

    &.-projectSelect {
      @media screen and (max-width: 639px), (max-height: 639px) {
        max-height: 272px !important;
      }

      @media screen and (max-height: 639px) {
        max-height: 116px !important;
      }
    }

    @media screen and (max-width: 1024px) {
      max-height: 272px;
    }

    @media screen and (max-height: 639px) {
      max-height: $ref-sizing-168;
    }
  }

  &_backToSignIn {
    display: flex;
    justify-content: center;
    margin-top: $ref-sizing-24;

    a {
      font-size: $typography-body-sm-font-size;
      line-height: $typography-body-sm-line-height;
      //font-weight: $typography-body-sm-font-weight;
      font-weight: 400;
    }
  }
}

.b-authorization-loginPage-dark {
  &_container {
    background: $dark-bgd-default;
  }

  &_bottomInfo {
    &_textInfo {
      color: $dark-on-bgd-srf-4;
      font-weight: 400;
      line-height: $typography-body-md-line-height;
      font-size: $typography-body-md-font-size;
    }
  }

  &_logoContainer {
    &__header {
      font-size: $typography-label-lg-font-size;
      line-height: $typography-label-lg-line-height;
      font-weight: $typography-label-lg-font-weight;
      color: $dark-primary;

      @media screen and (max-width: 639px), (max-height: 639px) {
        font-size: $typography-label-xxl-font-size;
        line-height: $typography-label-xxl-line-height;
        font-weight: $typography-label-xxl-font-weight;
      }
    }
  }

  &_form {
    background: $dark-srf-default;
    box-shadow: $z1-x $z1-y $z1-blur $z1-color;
    //border-radius: $border-radius-4;
    border-radius: 4px;
  }

  &_signedInContainer {
    &_title {
      color: $dark-on-bgd-srf-1;
    }
    &_subTitle {
      color: $dark-on-bgd-srf-1;
    }
  }

  &_forgotPassword {
    a {
      color: $dark-secondary;

      &:hover {
        color: lighten($dark-secondary, 20%);
      }
    }
  }

  &_backToSignIn {
    a {
      color: $dark-secondary;

      &:hover {
        color: lighten($dark-secondary, 20%);
      }
    }
  }

  &_signOut {
    color: $dark-secondary;

    &:hover {
      color: lighten($dark-secondary, 20%);
    }
  }

  &_textLabel {
    color: $dark-on-bgd-srf-1;
  }

  &_clientsList {
    background: $dark-bgd-variant;
  }

  &_menuItemClients {
    &:focus {
      background: $dark-state-bgd-srf-container-click !important;
    }
    &:hover {
      background: $dark-state-bgd-srf-container-hover !important;
    }
  }
}

.b-authorization-loginPage-light {
  &_container {
    background: $light-bgd-default;
  }

  &_bottomInfo {
    &_textInfo {
      color: $light-on-bgd-srf-4;
      font-weight: 400;
      line-height: $typography-body-md-line-height;
      font-size: $typography-body-md-font-size;
    }
  }

  &_logoContainer {
    &__header {
      font-size: $typography-label-lg-font-size;
      line-height: $typography-label-lg-line-height;
      font-weight: $typography-label-lg-font-weight;
      color: $light-primary;
    }
  }

  &_form {
    background: $light-srf-default;
    box-shadow: $z1-x $z1-y $z1-blur $z1-color;
    border-radius: 4px;
  }

  &_signedInContainer {
    &_title {
      color: $light-on-bgd-srf-1;
    }
    &_subTitle {
      color: $light-on-bgd-srf-1;
    }
  }

  &_forgotPassword {
    a {
      color: $light-secondary;

      &:hover {
        color: lighten($light-secondary, 20%);
      }
    }
  }

  &_backToSignIn {
    a {
      color: $light-secondary;

      &:hover {
        color: lighten($light-secondary, 20%);
      }
    }
  }

  &_signOut {
    color: $light-secondary;

    &:hover {
      color: lighten($light-secondary, 20%);
    }
  }

  &_textLabel {
    color: $light-on-bgd-srf-1;
  }

  &_clientsList {
    background: $light-bgd-variant;
  }

  &_menuItemClients {
    &:focus {
      background: $light-state-bgd-srf-container-click !important;
    }
    &:hover {
      background: $light-state-bgd-srf-container-hover !important;
    }
  }
}

.plugLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 750px;
  img {
    width: 350px;
  }

  @media screen and (max-width: 1367px) {
    width: 300px;
    img {
      width: 200px;
    }
  }
}
.plugContainer {
  display: flex;
  width: 750px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(42, 47, 64, 0.7);
  padding: 30px;

  svg {
    width: 100px;
    //fill: #5877A2;
    fill: #5f6282;
    //background: rgb(84, 99, 119);
    //border-radius: 50%;
    //padding: 15px;
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;

    .title {
      font-size: 30px;
      color: #fff;
      margin: 20px 0;
    }
    .text {
      display: flex;
      align-items: center;
      margin: 10px 0;
      text-align: center;
      line-height: 100%;
      color: #fff;
      font-size: 21px;
      img {
        width: 30px;
        margin: 5px;
      }
    }
  }
  .maintenanceIcon {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 1367px) {
    width: 300px;
    svg {
      width: 50px;
    }
    .textContainer {
      padding-bottom: 50px;
      .title {
        font-size: 21px;
        text-align: center;
      }
      .text {
        font-size: 15px;
        display: block;
        margin: 3px 0;
        text-align: center;
        img {
          width: 20px;
          margin: 0;
          margin-bottom: -5px;
        }
      }
    }
    .maintenanceIcon {
      margin-bottom: 20px;
    }
  }
}

//_____

.nonPlugContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('~../../../assets/images/bg-unlogined-new.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .left_svg {
    width: 80px;
    height: 80px;
  }
  .right_svg {
    width: 200px;
    height: 80px;
    path {
      fill: #fff;
    }
  }
}

.b-PlugView-dark,
.b-PlugView-light {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 80px;
  &_info {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: $ref-spacing-40;
    &_container {
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: $ref-spacing-16;
    }
    &_title {
      line-height: $typography-title-lg-line-height;
      font-weight: $typography-title-lg-font-weight;
      font-family: $typography-title-lg-font-family;
      font-size: $typography-title-lg-font-size;
      letter-spacing: $typography-title-lg-letter-spacing;
    }
    &_date_text {
      font-family: $typography-headline-md-font-family;
      font-weight: $typography-headline-md-font-weight;
      line-height: $typography-headline-md-line-height;
      font-size: $typography-headline-md-font-size;
      letter-spacing: $typography-headline-md-letter-spacing;
      text-align: center;
    }
    &_text {
      line-height: $typography-body-lg-line-height;
      font-weight: $typography-body-lg-font-weight;
      font-family: $typography-body-lg-font-family;
      font-size: $typography-body-lg-font-size;
      letter-spacing: $typography-body-lg-letter-spacing;
      text-align: center;
    }
  }

  @media screen and (max-width: 1024px) {
    gap: 60px;
  }

  @media screen and (max-width: 960px) {
    gap: $ref-spacing-20;
    &_info {
      gap: $ref-spacing-20;
    }
  }
}

.b-PlugView-dark {
  &_info {
    &_title {
      color: $dark-primary;
      div {
        color: $dark-primary;
      }
    }
    &_date_text {
      color: $dark-on-bgd-srf-1;
      div {
        color: $dark-on-bgd-srf-1;
      }
    }
    &_text {
      color: $dark-on-bgd-srf-2;
      div {
        color: $dark-on-bgd-srf-2;
      }
    }
  }
}

.b-PlugView-light {
  background-color: $light-on-secondary;
  &_info {
    &_title {
      color: $light-primary;
      div {
        color: $light-primary;
      }
    }
    &_date_text {
      color: $light-on-bgd-srf-1;
      div {
        color: $light-on-bgd-srf-1;
      }
    }
    &_text {
      color: $light-on-bgd-srf-2;
      div {
        color: $light-on-bgd-srf-2;
      }
    }
  }
}
