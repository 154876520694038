@font-face {
  font-display: fallback;
  font-family: 'San Francisco Text';
  font-style: normal;
  font-weight: 400;
  src: url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.eot?#iefix)
      format('embedded-opentype'),
    url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff2) format('woff2'),
    url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.woff) format('woff'),
    url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-medium-webfont.ttf) format('truetype');
}

@font-face {
  font-display: fallback;
  font-family: 'San Francisco Text';
  font-style: normal;
  font-weight: 600;
  src: url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.eot?#iefix)
      format('embedded-opentype'),
    url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff2) format('woff2'),
    url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.woff) format('woff'),
    url(https://applesocial.s3.amazonaws.com/assets/styles/fonts/sanfrancisco/sanfranciscodisplay-semibold-webfont.ttf) format('truetype');
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNovaA-Light.eot');
  src: url('../fonts/ProximaNovaA-Light.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNovaA-Light.woff2') format('woff2'),
    url('../fonts/ProximaNovaA-Light.woff') format('woff'), url('../fonts/ProximaNovaA-Light.ttf') format('truetype');
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Extrabld.eot');
  src: url('../fonts/ProximaNova-Extrabld.eot?#iefix') format('embedded-opentype'),
    url('../fonts/ProximaNova-Extrabld.woff2') format('woff2'), url('../fonts/ProximaNova-Extrabld.woff') format('woff'),
    url('../fonts/ProximaNova-Extrabld.ttf') format('truetype');
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNovaA-Bold.eot');
  src: url('../fonts/ProximaNovaA-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNovaA-Bold.woff2') format('woff2'),
    url('../fonts/ProximaNovaA-Bold.woff') format('woff'), url('../fonts/ProximaNovaA-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/proxima_nova_semibold.eot'); /* IE 9 Compatibility Mode */
  src: url('../fonts/proxima_nova_semibold.eot?#iefix') format('embedded-opentype'),
    /* IE < 9 */ url('../fonts/proxima_nova_semibold.woff2') format('woff2'),
    /* Super Modern Browsers */ url('../fonts/proxima_nova_semibold.woff') format('woff'),
    /* Firefox >= 3.6, any other modern browser */ url('../fonts/proxima_nova_semibold.ttf') format('truetype'),
    /* Safari, Android, iOS */ url('../fonts/proxima_nova_semibold.svg#proxima_nova_semibold') format('svg'); /* Chrome < 4, Legacy iOS */
  font-weight: 600;
  font-style: normal;
  font-display: swap;
}
@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNovaT-Thin.eot');
  src: url('../fonts/ProximaNovaT-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNovaT-Thin.woff2') format('woff2'),
    url('../fonts/ProximaNovaT-Thin.woff') format('woff'), url('../fonts/ProximaNovaT-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Bold.eot');
  src: url('../fonts/ProximaNova-Bold.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNova-Bold.woff2') format('woff2'),
    url('../fonts/ProximaNova-Bold.woff') format('woff'), url('../fonts/ProximaNova-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Black.eot');
  src: url('../fonts/ProximaNova-Black.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNova-Black.woff2') format('woff2'),
    url('../fonts/ProximaNova-Black.woff') format('woff'), url('../fonts/ProximaNova-Black.ttf') format('truetype');
  font-weight: 900;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNovaA-Thin.eot');
  src: url('../fonts/ProximaNovaA-Thin.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNovaA-Thin.woff2') format('woff2'),
    url('../fonts/ProximaNovaA-Thin.woff') format('woff'), url('../fonts/ProximaNovaA-Thin.ttf') format('truetype');
  font-weight: 100;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Proxima Nova';
  src: url('../fonts/ProximaNova-Regular.eot');
  src: url('../fonts/ProximaNova-Regular.eot?#iefix') format('embedded-opentype'), url('../fonts/ProximaNova-Regular.woff2') format('woff2'),
    url('../fonts/ProximaNova-Regular.woff') format('woff'), url('../fonts/ProximaNova-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}
