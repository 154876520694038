.input_wrapper {
  position: relative;
  color: var(--gray-60);
  width: 100%;
  border-radius: 3px;
  border: 1px solid transparent;
}

.input_placeholder_block {
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 40px;
  background: #ffffff;
  border-radius: 3px;

  label {
    display: flex;
    margin-left: 10px;
    font-family: 'Proxima Nova';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    color: #838ea2;
  }
}

.input {
  width: 100%;
  height: 25px;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
  border: none !important;
  outline: none;
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  color: #38455e;

  &_disable {
    //pointer-events: none;
    //opacity: 0.5;
    background-color: inherit;
  }
}

.input_error {
  border: 1px solid var(--rad);
  color: var(--rad);
}

.error {
  border: 1px solid red;

  label {
    color: red;
  }
}
