.b-flatLLoader {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;

  &__loader {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
  }

  &__progressBar {
    width: 85%;
    position: absolute;
    bottom: 40px;
    z-index: 1;
  }
}
