.checkbox {
  .check-box-container {
    label {
      margin-left: 8px;
      font-family: 'Proxima Nova';
      display: flex;
      font-size: 14px;
      user-select: none;
    }
  }

  .check-box-body {
    outline: 0 !important;

    &:focus-visible {
      input:before {
        outline: 1px solid #838ea2;
        border-color: #838ea2;
      }
    }
  }

  .check-box-wrap {
    display: flex;

    input {
      &:disabled {
        cursor: not-allowed;
        &:before {
          background-color: #f4f4f4;
        }
      }
    }
  }

  .indicator {
    left: 0;
    position: absolute;
    content: '';
    width: 12px;
    height: 12px;
    display: block;
    border-radius: 2px;
    background-color: #ffffff;
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    top: 50%;
    transform: translateY(-50%);
    border: 1px solid #8f8f91;

    &.lg {
      width: 20px;
      height: 20px;
      border-radius: 5px;
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
    }
  }

  input:checked + .indicator .checkIcon {
    display: block;
    color: #00a33d;
    position: absolute;
    font-size: 9px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 12px;
  }

  input:checked + .indicator {
    border: 1px solid #00a33d;
  }

  .text {
    font-weight: 400;
    font-size: 13px;
    padding-left: 26px;
    user-select: none;
    -webkit-touch-callout: none;
    -webkit-user-select: none;
    color: #111111;
    width: max-content;

    &.bold {
      font-weight: 700;
    }
  }

  .error {
    border-color: #ff001c;
  }

  .black {
    color: #111111;
  }

  .checkIcon {
    display: none;
  }

  .lg {
    width: 16px;
    min-width: 16px;
    height: 16px;
    min-height: 16px;
  }

  input[type='checkbox'] {
    position: relative;
    cursor: pointer;
    display: flex;
    align-items: center;
    width: 12px;
    justify-content: center;
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    outline: none;

    &:focus-visible {
      outline-offset: 0;
    }
  }
  input[type='checkbox']:focus-visible:before {
    background: var(--primay-yellow);
    box-shadow: 0px 0px 0px 3px var(--primay-yellow);
  }

  input[type='checkbox']:focus-visible:checked:before {
    box-shadow: 0px 0px 0px 3px var(--primay-yellow);
  }

  input[type='checkbox']:before {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0px;
    left: 0px;
    background-color: #fff;
    border: 1px solid var(--color1);
    border-radius: 1px;
  }
  input[type='checkbox']:checked:before {
    content: '';
    display: block;
    position: absolute;
    width: 12px;
    height: 12px;
    top: 0;
    left: 0;
    border-radius: 1px;
    background-color: var(--color1);
    border: 1px solid var(--color1);
  }
  input[type='checkbox']:checked:after {
    content: '';
    display: block;
    width: 3px;
    height: 7px;
    border: solid white;
    border-width: 0 2px 2px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);

    @media screen and (max-width: 1200px) {
      margin: 0 0 0 2px;
    }
  }

  // custom checkbox styles (by ag)
  .checkbox {
    display: flex;
    align-items: center;

    input {
      position: fixed;
      left: 0;
      top: 0;
      opacity: 0;
      z-index: -1;
    }

    &_fakeLabel {
      margin-left: 8px;
      color: var(--gray-60);
    }
  }
}
