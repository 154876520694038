.nfLegend {
  foreignObject {
    width: 240px;
    height: 190px;

    @media screen and (max-width: 450px) {
      width: 220px;
      height: 200px;
    }
  }

  &.-expanded {
    foreignObject {
      width: 500px;
      height: 190px;

      @media screen and (max-width: 450px) {
        width: 230px;
        height: 500px;
      }
    }
  }
  &.withoutFL {
    foreignObject {
      width: 370px;
      height: 190px;

      @media screen and (max-width: 450px) {
        width: 220px;
        height: 320px;
      }
    }
  }
}

.b-legend {
  position: relative;
  width: 220px;
  height: 173px;
  font-size: 12px;
  background: #f1f4f8;
  border: 1px solid #5e739e;
  border-radius: 2px;
  padding: 7px;

  @media screen and (max-width: 450px) {
    width: 200px;
    height: 180px;
  }

  &.-expanded {
    width: 484px;

    @media screen and (max-width: 450px) {
      width: 210px;
      height: 480px;
    }
  }

  &.withoutFL {
    width: 344px;

    @media screen and (max-width: 450px) {
      width: 200px;
      height: 300px;
    }
  }

  &-container {
    padding: 0 10px 0px 20px;
  }

  &-header {
    display: flex;
    align-items: center;
    position: relative;
    border-bottom: 1px solid var(--color-secondary);
    justify-content: center;
    height: 30px;

    .mainTitle,
    .groupTitle {
      font-size: 12px;
      line-height: 17px;
    }

    .groupTitle {
      color: var(--color-secondary);
      font-weight: 400;
    }
  }

  &-content {
    margin-top: 6px;
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;

    @media screen and (max-width: 450px) {
      flex-wrap: wrap;
    }

    .nfStatistic {
      cursor: default;
      margin-top: 10px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;

      &-item {
        margin-bottom: 7px;

        span {
          color: var(--color-secondary);
        }
      }
    }

    .nfGroupStatistic {
      margin-top: 6px;
      display: flex;
      align-items: flex-start;
      flex-direction: column;
      overflow-y: auto;
      overflow-x: hidden;
      height: 132px;
      white-space: nowrap;
      text-overflow: ellipsis;

      .no-options {
        color: var(--color-secondary);
      }

      &-item {
        cursor: default;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
        width: 95%;

        &:hover {
          span {
            color: var(--color);
          }
        }

        .label {
          max-width: 70%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: var(--color-secondary);
        }

        .labelCount {
          margin-left: 5px;
          max-width: 30%;
          color: var(--color-secondary);
        }

        .colorIndicator {
          margin-right: 7px;
          width: 20px;
          min-width: 10px;
          height: 10px;
          border-radius: 9px;
        }
      }

      .nf-GroupStatisticFl {
        cursor: default;
        margin-bottom: 7px;
        display: flex;
        align-items: center;
        // width: 90%;

        &:hover {
          span {
            color: var(--color);
          }
        }

        .label {
          max-width: 70%;
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          color: var(--color-secondary);
        }

        .labelCount {
          margin-left: 5px;
          max-width: 30%;
          color: var(--color-secondary);
        }

        .colorIndicatorFlowLine {
          margin-right: 3px;
          width: 10px;
          min-width: 10px;
          height: 5px;
          border-radius: 5px;

          &.-mr-7 {
            margin-right: 7px;
          }
        }
      }
    }

    .flowline {
      margin-right: 20px;
      width: 100%;
      max-width: 110px;

      @media screen and (max-width: 450px) {
        margin-left: 10px;
        margin-top: 10px;
        max-width: 180px;
      }
    }

    .right {
      width: 100%;
      max-width: 180px;

      @media screen and (max-width: 450px) {
        margin-left: 10px;
        margin-top: 10px;
      }
    }

    .left {
      min-width: 120px;
      margin-left: 10px;
      margin-right: 25px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }
}
.expandSvgLegend {
  width: 20px;
  height: 30px;
  position: absolute;
  left: 2px;
  top: 80px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 13px;
    height: 13px;

    path {
      fill: var(--color-secondary);
    }
  }

  &.-expanded {
    top: 35px;
  }
}

.closeLegend {
  position: absolute;
  right: 5px;
  top: 12px;
  width: 20px;
  height: 20px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  svg {
    width: 13px;
    height: 13px;

    path {
      fill: var(--color-secondary);
    }
  }
}
