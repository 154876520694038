.react-calendar {
  width: 350px;
  max-width: 100%;
  background: var(--background-grid);
  //letter-spacing: -0.06em;
  //border: 1px solid #a0a096;
  //font-family: Arial, Helvetica, sans-serif;
  line-height: 1.125em;
  font-family: var(--font-general);
}
.react-calendar--doubleView {
  width: 700px;
}
.react-calendar__navigation {
  button {
    color: var(--color);
    font-size: 16px;
    font-family: var(--font-general);
  }
}
.react-calendar--doubleView .react-calendar__viewContainer {
  display: flex;
  margin: -0.5em;
}
.react-calendar--doubleView .react-calendar__viewContainer > * {
  width: 50%;
  margin: 0.5em;
}
.react-calendar,
.react-calendar *,
.react-calendar *:before,
.react-calendar *:after {
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
}
.react-calendar button {
  margin: 0;
  border: 0;
  outline: none;
}
.react-calendar button:enabled:hover {
  cursor: pointer;
}
.react-calendar__navigation {
  height: 44px;
  margin-bottom: 1em;
}
.react-calendar__navigation button {
  min-width: 44px;
  background: none;
}
.react-calendar__navigation button:enabled:hover,
.react-calendar__navigation button:enabled:focus {
  background-color: var(--background-sheet);
}
.react-calendar__navigation button[disabled] {
  //background-color: #f0f0f0;
  opacity: 0.4;
}
.react-calendar__month-view__weekdays {
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
  font-size: 0.75em;
}
.react-calendar__month-view__weekdays__weekday {
  padding: 0.5em;
  color: var(--color);

  abbr {
    font-family: var(--font-general);
    text-decoration: none;
  }
}
.react-calendar__month-view__weekNumbers {
  font-weight: bold;
}
.react-calendar__month-view__weekNumbers .react-calendar__tile {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.75em;
  padding: calc(0.75em / 0.75) calc(0.5em / 0.75);
}
.react-calendar__tile {
  color: var(--color);
}
.react-calendar__month-view__days__day--weekend {
  background-color: var(--background-sheet);
}
.react-calendar__month-view__days__day--neighboringMonth {
  background-color: var(--background-sheet);
}
.react-calendar__year-view .react-calendar__tile,
.react-calendar__decade-view .react-calendar__tile,
.react-calendar__century-view .react-calendar__tile {
  padding: 2em 0.5em;
}
.react-calendar__tile {
  max-width: 100%;
  text-align: center;
  padding: 0.75em 0.5em;
  background: none;
}
.react-calendar__tile:disabled {
  opacity: 0.4;
}
.react-calendar__tile:enabled:hover,
.react-calendar__tile:enabled:focus {
  background-color: var(--background-sheet);
}
.react-calendar__tile--now {
  background: var(--color2);
}
.react-calendar__tile--now:enabled:hover,
.react-calendar__tile--now:enabled:focus {
  background-color: var(--background-sheet);
}
.react-calendar__tile--hasActive {
  background: var(--button-active-menu);
}
.react-calendar__tile--hasActive:enabled:hover,
.react-calendar__tile--hasActive:enabled:focus {
  background: var(--button-active-menu);
}
.react-calendar__tile--active {
  background: var(--color1);
  //color: white;
}
.react-calendar__tile--active:enabled:hover,
.react-calendar__tile--active:enabled:focus {
  background-color: var(--background-sheet);
}
.react-calendar--selectRange .react-calendar__tile--hover {
  background-color: var(--background-sheet);
}
