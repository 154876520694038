.menuItem {
  position: relative;
  margin: 2px 0 !important;
  //height: 39px !important;
  padding: 8px 16px 7px 16px !important;
  height: 44px;
  text-transform: inherit;
  color: var(--background-select-text) !important;
  font-size: var(--font-menu-size) !important;
  font-family: var(--font-general) !important;
  background-color: var(--background-select);
  font-weight: var(--font-weight);

  em,
  span,
  p {
    font-style: inherit;
    font-family: var(--font-general) !important;
    color: var(--background-select-text) !important;
    font-size: var(--font-menu-size) !important;
  }
  em {
    font-weight: var(--font-weight);
  }

  //&:before {
  //  position: absolute;
  //  left: 0;
  //  bottom: 0;
  //  width: 100%;
  //  height: 1px;
  //  background-color: #40466a;
  //  content: '';
  //}

  &:hover {
    color: var(--background-select-hover-text) !important;
    background-color: var(--background-select-hover) !important;
    p,
    em {
      color: var(--background-select-hover-text) !important;
      background-color: var(--background-select-hover) !important;
    }
  }
  &:focus {
    background-color: var(--background-select-hover) !important;
  }

  &.-hasActiveNesting {
    .title {
      color: #f3a359 !important;
    }
  }

  &.-disabled {
    opacity: 0.38;
    cursor: default;
    background-color: transparent !important;

    p,
    em {
      background-color: transparent !important;
    }
  }

  &__searchable {
    padding-left: 0 !important;
  }
  .title {
    font-weight: var(--font-weight);

    &.-isTagAuto {
      color: #c58437 !important;
    }

    &.-isTagImported {
      color: #5b954e !important;
    }
  }
}

.clients_menuItem {
  padding: 0 !important;

  &_container {
    display: flex;
    align-items: center;
    width: 100%;
    height: 100%;
    padding: 0 16px;
  }
}

.select__noItems {
  color: #999999;
  display: flex;
  font-size: 12px;
  justify-content: center;
  box-sizing: border-box;
}
