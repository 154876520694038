.container {
  height: 100vh;
  width: 100%;
  display: flex;

  @media screen and (max-width: 767px) {
    justify-content: center;
  }
}

.leftPanel {
  // height: 100vh;
  min-width: 370px;
  // width: Min(470px, 85%);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 100px;

  .left_svg {
    width: 80px;
    height: 80px;
  }
  .right_svg {
    width: 200px;
    height: 80px;
    path {
      fill: #fff;
    }
  }

  @media screen and (max-width: 767px) {
    min-width: 350px;
    margin-left: 0;
  }
}

.logoContainer {
  margin-bottom: 35px;

  &__header {
    font-size: 14px;
    text-align: center;
    color: #fc8f14;
    margin-top: 14px;
  }
}

.form {
  width: Min(370px, 100%);
  // box-sizing: border-box;
  padding: 45px 40px 20px;
  // background-color: rgba(45, 51, 67, 0.83);
  // font-family: var(--font-general);

  background: var(--background-main);
  // border: 1px solid #162771;
  border: 1px solid black;
  box-sizing: border-box;
  box-shadow: -1px 1px 10px rgba(135, 175, 255, 0.3);
  border-radius: 5px;

  @media screen and (max-width: 767px) {
    width: Min(320px, 100%);
    // box-sizing: border-box;
    padding: 25px 20px 10px;
  }

  &__header {
    margin-bottom: 10px;
  }

  &__loginContainer {
    margin-bottom: 45px;
    button {
      border: var(--background-border);
      margin-top: 10px;
      padding: 10px;
      width: 100%;
      height: 40px;
      background-color: var(--button2-active);
      color: var(--button2-active-text);
      font-size: 0.8em;
      border-radius: 4px;
      &:hover {
        background-color: var(--button2-hover);
        color: var(--button2-hover-text);
        border: 1px solid transparent;
        cursor: pointer;
      }
    }
  }

  &__text {
    font-size: 12px;
    font-family: var(--font-general);
    color: var(--color);
    text-align: center;
    margin-bottom: -10px;
  }
}
.passwordBlock {
  position: relative;
}

.forgot {
  display: flex;
  justify-content: flex-start;

  a {
    color: red;
    font-size: 12px;
    font-family: var(--font-general);

    &:hover {
      color: red;
    }
  }
}

.forgotPassword {
  position: absolute;
  bottom: 60px;
  display: flex;
  justify-content: flex-start;
  margin-left: 15px;

  a {
    color: red;
    font-size: 10px;
    font-family: var(--font-general);

    &:hover {
      color: red;
    }
  }
}

.loginError {
  display: flex;
  justify-content: center;
  color: red;
  font-size: 12px;
  font-family: var(--font-general);
}
.plugLogoContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 750px;
  img {
    width: 350px;
  }

  @media screen and (max-width: 1367px) {
    width: 300px;
    img {
      width: 200px;
    }
  }
}
.plugContainer {
  display: flex;
  width: 750px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: rgba(42, 47, 64, 0.7);
  padding: 30px;

  svg {
    width: 100px;
    //fill: #5877A2;
    fill: #fff;
    //background: rgb(84, 99, 119);
    //border-radius: 50%;
    //padding: 15px;
  }
  .textContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-bottom: 120px;

    .title {
      font-size: 30px;
      color: #fff;
      margin: 20px 0;
    }
    .text {
      display: flex;
      align-items: center;
      margin: 10px 0;
      text-align: center;
      line-height: 100%;
      color: #fff;
      font-size: 21px;
      img {
        width: 30px;
        margin: 5px;
      }
    }
  }
  .maintenanceIcon {
    margin-bottom: 30px;
  }
  @media screen and (max-width: 1367px) {
    width: 300px;
    svg {
      width: 50px;
    }
    .textContainer {
      padding-bottom: 50px;
      .title {
        font-size: 21px;
        text-align: center;
      }
      .text {
        font-size: 15px;
        display: block;
        margin: 3px 0;
        text-align: center;
        img {
          width: 20px;
          margin: 0;
          margin-bottom: -5px;
        }
      }
    }
    .maintenanceIcon {
      margin-bottom: 20px;
    }
  }
}

.textLabel {
  font-size: 12px;
  font-family: var(--font-general);
  font-weight: 300;
  color: red;
  line-height: 24px;
  margin-left: 15px;
}

.clientsList {
  background: red;
  border-radius: 4px;
  max-height: 180px;
  overflow: auto;
  margin-bottom: 20px;
}

.b-routerContainer {
  display: flex;
  background-color: var(--background-main);
  min-height: 100%;

  &__aside {
    //flex: 0 0 15%;
    //width: 15%;
    background-color: var(--background-main);
    min-height: 100vh;
    //height: 100%;var(--navIcon-default);
    //position: fixed;
    left: 0;
    top: 58px;
    z-index: 1002;
    //border-right: 1px solid #2d323f;
    margin-right: 0;
    border-right: var(--background-border);

    a {
      position: relative;
      height: 70px;
      display: block;

      @media screen and (max-width: 767px) {
        height: 58px;
      }
    }
    .themeSwitcher {
      position: absolute;
      bottom: 15px;
      left: -2px;
    }
  }
  &__main {
    //margin-left: 15%;
    //overflow: hidden;
    //max-width: 93%;
    //width: 100%;
    //min-height: 100%;
    //@media screen and (max-width: 980px){
    //  margin-left: 7%;
    //  max-width: 93%;
    //}
  }
  &__openedMenu {
    width: calc(100vw - 181px);
    overflow: hidden;
  }
  &__closedMenu {
    // width: calc(100vw - 90px);
    overflow: hidden;
    flex-grow: 1;
    // padding-right: 10px;
  }
  .svg {
    &__iconPath {
      fill: var(--navIcon-default);
    }
    &__iconStroke {
      stroke: var(--navIcon-default);
    }
  }
}

.nonPlugContainer {
  height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-image: url('../../../assets/images/bg-unlogined-new.svg');
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;

  .left_svg {
    width: 80px;
    height: 80px;
  }
  .right_svg {
    width: 200px;
    height: 80px;
    path {
      fill: #fff;
    }
  }
}
