body > iframe[style*='2147483647']:not([id='webpack-dev-server-client-overlay']) {
  display: none;
}

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: absolute;
  bottom: 0;
  top: 0;
  left: 0;
  right: 0;
  overflow: hidden;
}

body,
span,
a,
div,
button,
option,
p {
  font-family: Proxima Nova;
  font-style: normal;
  color: var(--color);
  font-weight: 400;
}

a:focus-visible,
b:focus-visible,
span:focus-visible,
button:focus-visible,
option:focus-visible,
div:focus-visible {
  outline: var(--orange) auto 1px;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a {
  text-decoration: none;
}

ul {
  list-style-type: none;
  margin: 0;
  padding: 0;
}

p {
  margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

button {
  transition: 0.3s ease-in-out;
  border: none;
  font-family: inherit;
  cursor: pointer;
}

.blue-text {
  color: var(--primary-navy);
}

.orange-text {
  color: var(--primary-70);
}

.orange-light-text {
  color: var(--primary-60);
}

.white-text {
  color: var(--white);
}

.gray-text {
  color: var(--gray-60) !important;
}

.text-center {
  text-align: center;
  max-width: 545px;
}

.b-title {
  font-family: Proxima Nova;
  font-weight: bold;
  font-size: 24px;
  margin: 10px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
}

.pre-title {
  font-family: Proxima Nova;
  font-weight: bold;
  font-size: 20px;
  margin: 10px;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;
  text-align: center;
}

.sub-title {
  font-family: Proxima Nova;
  font-weight: bold;
  font-size: 16px;
  margin: 10px;
  width: 351px;
  text-align: center;
  font-stretch: normal;
  font-style: normal;
  letter-spacing: normal;

  @media screen and (max-width: 824px) {
    width: 328px;
  }
}

.title-height {
}

.text-description {
  font-family: Proxima Nova;
  font-size: 16px;
  line-height: 24px;
}

.text-long-description {
  font-family: Proxima Nova;
  font-size: 14px;
}

.bold {
  font-weight: 900;
}

.text-container {
  margin: 0 30px 30px 30px !important;
  text-align: center;
  max-width: 545px;
}

button.links {
}

.links {
  color: var(--primary-60);
  letter-spacing: 0.16px;
  cursor: pointer;
}

.links_underline_text {
  text-decoration: underline;
}

.sub-container-row {
  display: flex;
  flex-direction: row;
  margin: 40px;
  align-items: center;
  justify-content: center;
}

.content-left {
  align-items: start;
  margin-left: 9px;
  margin-bottom: 16px;
}

.content-right {
  align-items: end;
}

.content-center {
  align-items: center;
}

.content-center-wrapper {
  flex: 1;
  display: flex;
  align-items: center;
  width: 100%;
  justify-content: center;
}

.button-radio {
  cursor: pointer;
}

.error-notification {
  display: flex;
  min-height: 21px;
  width: calc(100% - 14px);
  margin-bottom: 5px;
  margin-left: 14px;

  @media screen and (max-width: 824px) {
    min-width: 260px;
  }
}

.error-notification-text {
  font-size: 12px;
  color: var(--rad);
  line-height: 16px;
}

.font-s {
  font-size: 14px;
}

.font-m {
  font-size: 16px;
}

.font-l {
  font-size: 20px;
}

.font-x {
  font-size: 24px;
}

.font-spacing-s {
  line-height: 1.43;
  letter-spacing: 0.16px;
}

.font-spacing-m {
  line-height: 1.43;
  letter-spacing: 0.16px;
}

.font-spacing-l {
  line-height: 1.43;
  letter-spacing: 0.16px;
}

.font-spacing-x {
  line-height: 1.43;
  letter-spacing: 0.16px;
}

.text_spacing {
  letter-spacing: 3px;
}

.popover {
  z-index: 1;
}

.auth-iframe {
  height: 100vh;
  width: 100%;
  border: 0;
  padding: 0;
  margin: 0;
}

.auth-iframe-logout {
  height: 0px;
  width: 0px;
  border: 0;
  padding: 0;
  margin: 0;
}

.btn {
  font-size: 14px;
  position: relative;
  color: var(--primary-navy);
  background: transparent;
  display: flex;
  svg {
    margin-right: 6px;
  }
}

.twitter-picker {
  width: 100% !important;
}

@mixin scrollbars($size, $foreground-color, $background-color: mix($foreground-color, white, 50%)) {
  // For Internet Explorer
  //.infinite-scroll-component, .b-tableNeedList, .CreateProject, .b-location__wrapTable, .b-phasePlan__wrapTable, .EditableTable, .sandBoxRequestCardWrap
  * {
    scrollbar-width: thin;
    &::-webkit-scrollbar {
      width: $size;
      height: $size;
    }

    &::-webkit-scrollbar-thumb {
      background: var(--color1);
      border-radius: 2px;
    }

    &::-webkit-scrollbar-track {
      background: var(--background-select);
      border-radius: 2px;
    }

    scrollbar-face-color: var(--background-grid);
    scrollbar-track-color: $background-color;
  }
}

.buildingSelect {
  display: flex;
  align-items: center;
  overflow: hidden;

  svg {
    margin-left: 5px;
    height: 20px;
    width: 20px;
    min-width: 20px;
  }
  em {
    text-overflow: ellipsis;
    overflow: hidden;
  }
}

@include scrollbars(8px, #2d384e);
