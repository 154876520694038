@import '/designTokens/scss/_variablesDesignTokes';

.b-placeholderInfo-dark,
.b-placeholderInfo-light {
  padding-top: $ref-spacing-0;
  padding-right: $ref-spacing-0;
  padding-bottom: $ref-spacing-0;
  padding-left: $ref-spacing-0;
  display: flex;
  align-items: center;
  overflow: hidden;
  max-width: 100%;

  &.-mobile {
    .b-placeholderInfo-dark,
    .b-placeholderInfo-light {
      &_detail_24,
      &_detail_32 {
        font-family: $typography-label-lg-font-family;
        font-weight: $typography-label-lg-font-weight;
        line-height: $typography-label-lg-line-height;
        font-size: $typography-label-lg-font-size;
        letter-spacing: $typography-label-lg-letter-spacing;
      }

      &_sub_24,
      &_sub_32 {
        font-family: $typography-body-md-font-family;
        font-weight: $typography-body-md-font-weight;
        line-height: $typography-body-md-line-height;
        font-size: $typography-body-md-font-size;
        letter-spacing: $typography-body-md-letter-spacing;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &_tooltipBasis {
    width: 100%;
  }

  &_content {
    display: flex;
    align-items: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    gap: $ref-spacing-8;
  }

  &_detailSubContainer {
    display: flex;
    flex-direction: column;
    width: 100%;
    max-width: 330px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  &_detail,
  &_sub {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &.-clickable {
      cursor: pointer;
      transition: color 0.1s;
    }
  }

  &_detail_24,
  &_detail_32 {
    font-family: $typography-label-md-font-family;
    font-weight: $typography-label-md-font-weight;
    line-height: $typography-label-md-line-height;
    font-size: $typography-label-md-font-size;
    letter-spacing: $typography-label-md-letter-spacing;
  }
  &_sub_24,
  &_sub_32 {
    font-family: $typography-body-sm-font-family;
    font-weight: $typography-body-sm-font-weight;
    line-height: $typography-body-sm-line-height;
    font-size: $typography-body-sm-font-size;
    letter-spacing: $typography-body-sm-letter-spacing;
    display: flex;
    padding: 0;
    align-items: center;
    gap: 6px;
    svg {
      width: 4px;
      height: 4px;
    }
    div {
      font-family: $typography-body-sm-font-family;
      font-weight: $typography-body-sm-font-weight;
      line-height: $typography-body-sm-line-height;
      font-size: $typography-body-sm-font-size;
      letter-spacing: $typography-body-sm-letter-spacing;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &.-headerTrunk {
      display: block;
    }
  }
  &_detail_56 {
    font-family: $typography-title-md-font-family;
    font-weight: $typography-title-md-font-weight;
    line-height: $typography-title-md-line-height;
    font-size: $typography-title-md-font-size;
    letter-spacing: $typography-title-md-letter-spacing;
  }
  &_sub_56 {
    font-family: $typography-body-md-font-family;
    font-weight: $typography-body-md-font-weight;
    line-height: $typography-body-md-line-height;
    font-size: $typography-body-md-font-size;
    letter-spacing: $typography-body-md-letter-spacing;
    display: flex;
    padding: 0;
    align-items: center;
    gap: 6px;
    svg {
      width: 4px;
      height: 4px;
    }
  }
}

.b-placeholderInfo-dark {
  &_detail_24,
  &_detail_32,
  &_detail_56 {
    color: $dark-on-bgd-srf-1;
  }
  &_sub_24,
  &_sub_32,
  &_sub_56 {
    color: $dark-on-bgd-srf-3;
    svg {
      circle {
        fill: $dark-secondary;
      }
    }
    div {
      color: $dark-on-bgd-srf-3;
    }
  }

  &_detail.-marked {
    color: $dark-primary;
  }

  &_detail.-clickable:hover {
    color: $dark-primary;
  }
}
.b-placeholderInfo-light {
  &_detail_24,
  &_detail_32,
  &_detail_56 {
    color: $light-on-bgd-srf-1;
  }
  &_sub_24,
  &_sub_32,
  &_sub_56 {
    color: $light-on-bgd-srf-3;
    svg {
      circle {
        fill: $dark-secondary;
      }
    }
    div {
      color: $light-on-bgd-srf-3;
    }
  }

  &_detail.-marked {
    color: $light-primary;
  }

  &_detail.-clickable:hover {
    color: $light-primary;
  }
}
