.b-miniLoader-dark,
.b-miniLoader-light {
  display: flex;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  &_root {
    &.-xs {
      width: 12px !important;
      height: 12px !important;
    }

    &.-sm {
      width: 16px !important;
      height: 16px !important;
    }

    &.-md {
      width: 20px !important;
      height: 20px !important;
    }

    &.-lg {
      width: 24px !important;
      height: 24px !important;
    }
  }
}
