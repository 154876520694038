.Logo {
  &__link {
    display: block;
    font-size: 0;

    .b-header & {
      @media screen and (max-width: 1200px) {
        margin: 0 15px;
      }
      @media screen and (max-width: 767px) {
        margin: 0 10px;
      }
    }
  }
  &__image {
    margin-right: 10px;

    .b-header & {
      margin: 0;
      @media screen and (max-width: 1023px) {
        width: 22px;
      }

      @media screen and (max-width: 767px) {
        margin: 0;
        width: 16px;
        height: 16px;
      }
    }
  }
  &__whatNew {
    img {
      width: 250px;
    }
  }
}

.miniLogo {
  width: 40px;
}
