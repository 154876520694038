// Do not edit directly
// Generated on Mon, 08 Jul 2024 10:03:19 GMT

$ref-spacing-0: 0px;
$ref-spacing-2: 2px;
$ref-spacing-4: 4px;
$ref-spacing-6: 6px;
$ref-spacing-8: 8px;
$ref-spacing-12: 12px;
$ref-spacing-14: 14px;
$ref-spacing-16: 16px;
$ref-spacing-20: 20px;
$ref-spacing-24: 24px;
$ref-spacing-28: 28px;
$ref-spacing-32: 32px;
$ref-spacing-40: 40px;
$ref-spacing-56: 56px;
$ref-spacing-64: 64px;
$ref-primary-brand-blue-45: rgb(247, 247, 247);
$ref-primary-brand-blue-50: rgb(240, 240, 240);
$ref-primary-brand-blue-53: #e5e7e9;
$ref-primary-brand-blue-55: rgb(223, 225, 229);
$ref-primary-brand-blue-60: rgb(204, 209, 219);
$ref-primary-brand-blue-70: rgb(171, 180, 199);
$ref-primary-brand-blue-80: rgb(141, 154, 178);
$ref-primary-brand-blue-90: rgb(114, 129, 158);
$ref-primary-brand-blue-100: rgb(89, 105, 137);
$ref-primary-brand-blue-200: rgb(68, 84, 117);
$ref-primary-brand-blue-300: rgb(49, 65, 97);
$ref-primary-brand-blue-400: rgb(34, 48, 77);
$ref-primary-brand-blue-450: rgb(27, 40, 66);
$ref-primary-brand-blue-500: rgb(21, 33, 56);
$ref-primary-brand-blue-550: rgb(15, 25, 46);
$ref-primary-brand-blue-600: rgb(11, 19, 36);
$ref-primary-brand-orange-55: rgb(252, 238, 222);
$ref-primary-brand-orange-60: rgb(252, 219, 181);
$ref-primary-brand-orange-70: rgb(252, 200, 141);
$ref-primary-brand-orange-80: rgb(252, 181, 101);
$ref-primary-brand-orange-90: rgb(252, 162, 60);
$ref-primary-brand-orange-100: rgb(252, 143, 20);
$ref-primary-brand-orange-200: rgb(224, 126, 16);
$ref-primary-brand-orange-300: rgb(186, 120, 45);
$ref-primary-brand-orange-400: rgb(148, 106, 59);
$ref-primary-brand-orange-450: rgb(110, 87, 61);
$ref-primary-brand-orange-500: rgb(71, 62, 51);
$ref-primary-brand-orange-550: rgb(33, 31, 29);
$ref-neutrals-white: rgb(255, 255, 255);
$ref-neutrals-black: rgb(0, 0, 0);
$ref-system-red-50: rgb(249, 87, 78);
$ref-system-red-100: rgb(255, 69, 58);
$ref-system-red-200: rgb(235, 47, 36);
$ref-system-green-50: rgb(145, 214, 92);
$ref-system-green-100: rgb(123, 215, 50);
$ref-system-green-200: rgb(97, 185, 30);
$ref-system-yellow-50: rgb(255, 207, 61);
$ref-system-yellow-100: rgb(255, 196, 18);
$ref-system-yellow-200: rgb(235, 176, 0);
$ref-system-blue-50: rgb(61, 158, 255);
$ref-system-blue-100: rgb(18, 136, 255);
$ref-system-blue-200: rgb(0, 117, 235);
$ref-tertiary-pink-50: rgb(252, 233, 238);
$ref-tertiary-pink-60: rgb(246, 189, 203);
$ref-tertiary-pink-70: rgb(241, 145, 168);
$ref-tertiary-pink-80: rgb(235, 101, 133);
$ref-tertiary-pink-90: rgb(229, 57, 98);
$ref-tertiary-pink-100: rgb(226, 35, 81);
$ref-tertiary-pink-200: rgb(199, 31, 72);
$ref-tertiary-pink-300: rgb(171, 27, 61);
$ref-tertiary-pink-400: rgb(143, 22, 51);
$ref-tertiary-pink-500: rgb(115, 18, 41);
$ref-tertiary-pink-600: rgb(87, 14, 31);
$ref-tertiary-pink-700: rgb(59, 9, 20);
$ref-tertiary-peach-50: rgb(252, 244, 240);
$ref-tertiary-peach-60: rgb(247, 211, 195);
$ref-tertiary-peach-70: rgb(242, 180, 153);
$ref-tertiary-peach-80: rgb(237, 149, 111);
$ref-tertiary-peach-90: rgb(232, 120, 72);
$ref-tertiary-peach-100: rgb(226, 92, 35);
$ref-tertiary-peach-200: rgb(199, 81, 31);
$ref-tertiary-peach-300: rgb(171, 70, 26);
$ref-tertiary-peach-400: rgb(143, 58, 22);
$ref-tertiary-peach-500: rgb(115, 47, 18);
$ref-tertiary-peach-600: rgb(87, 35, 13);
$ref-tertiary-peach-700: rgb(59, 24, 9);
$ref-tertiary-sand-50: rgb(249, 244, 227);
$ref-tertiary-sand-60: rgb(245, 231, 184);
$ref-tertiary-sand-70: rgb(245, 222, 144);
$ref-tertiary-sand-80: rgb(240, 209, 103);
$ref-tertiary-sand-90: rgb(235, 196, 63);
$ref-tertiary-sand-100: rgb(229, 183, 25);
$ref-tertiary-sand-200: rgb(196, 156, 20);
$ref-tertiary-sand-300: rgb(168, 134, 19);
$ref-tertiary-sand-400: rgb(140, 112, 14);
$ref-tertiary-sand-500: rgb(112, 90, 12);
$ref-tertiary-sand-600: rgb(84, 67, 8);
$ref-tertiary-sand-700: rgb(56, 44, 6);
$ref-tertiary-lime-50: rgb(246, 252, 240);
$ref-tertiary-lime-60: rgb(221, 247, 195);
$ref-tertiary-lime-70: rgb(197, 242, 153);
$ref-tertiary-lime-80: rgb(174, 237, 111);
$ref-tertiary-lime-90: rgb(151, 232, 72);
$ref-tertiary-lime-100: rgb(141, 227, 57);
$ref-tertiary-lime-200: rgb(124, 199, 52);
$ref-tertiary-lime-300: rgb(105, 171, 43);
$ref-tertiary-lime-400: rgb(89, 143, 37);
$ref-tertiary-lime-500: rgb(71, 115, 29);
$ref-tertiary-lime-600: rgb(54, 87, 23);
$ref-tertiary-lime-700: rgb(36, 59, 15);
$ref-tertiary-blue-50: rgb(240, 250, 252);
$ref-tertiary-blue-60: rgb(195, 235, 247);
$ref-tertiary-blue-70: rgb(153, 221, 242);
$ref-tertiary-blue-80: rgb(111, 208, 237);
$ref-tertiary-blue-90: rgb(72, 195, 232);
$ref-tertiary-blue-100: rgb(35, 181, 266);
$ref-tertiary-blue-200: rgb(31, 160, 199);
$ref-tertiary-blue-300: rgb(26, 137, 171);
$ref-tertiary-blue-400: rgb(22, 115, 143);
$ref-tertiary-blue-500: rgb(18, 92, 115);
$ref-tertiary-blue-600: rgb(13, 70, 87);
$ref-tertiary-blue-700: rgb(9, 47, 59);
$ref-tertiary-indigo-50: rgb(245, 240, 252);
$ref-tertiary-indigo-60: rgb(218, 195, 247);
$ref-tertiary-indigo-70: rgb(192, 153, 242);
$ref-tertiary-indigo-80: rgb(167, 111, 237);
$ref-tertiary-indigo-90: rgb(142, 72, 232);
$ref-tertiary-indigo-100: rgb(119, 35, 226);
$ref-tertiary-indigo-200: rgb(105, 31, 199);
$ref-tertiary-indigo-300: rgb(90, 26, 171);
$ref-tertiary-indigo-400: rgb(75, 22, 143);
$ref-tertiary-indigo-500: rgb(60, 18, 115);
$ref-tertiary-indigo-600: rgb(46, 13, 87);
$ref-tertiary-indigo-700: rgb(31, 9, 59);
$ref-tertiary-purple-50: rgb(251, 240, 252);
$ref-tertiary-purple-60: rgb(240, 195, 247);
$ref-tertiary-purple-70: rgb(230, 153, 242);
$ref-tertiary-purple-80: rgb(220, 111, 237);
$ref-tertiary-purple-90: rgb(210, 72, 232);
$ref-tertiary-purple-100: rgb(199, 35, 226);
$ref-tertiary-purple-200: rgb(175, 31, 199);
$ref-tertiary-purple-300: rgb(126, 22, 143);
$ref-tertiary-purple-400: rgb(101, 18, 115);
$ref-tertiary-purple-500: rgb(76, 13, 87);
$ref-tertiary-purple-600: rgb(52, 9, 59);
$ref-tertiary-purple-700: rgb(27, 5, 31);
$ref-tertiary-gray-50: rgb(247, 247, 247);
$ref-tertiary-gray-60: rgb(230, 230, 230);
$ref-tertiary-gray-70: rgb(212, 212, 212);
$ref-tertiary-gray-80: rgb(194, 194, 194);
$ref-tertiary-gray-90: rgb(176, 176, 176);
$ref-tertiary-gray-100: rgb(158, 158, 158);
$ref-tertiary-gray-200: rgb(135, 135, 135);
$ref-tertiary-gray-300: rgb(112, 112, 112);
$ref-tertiary-gray-400: rgb(89, 89, 89);
$ref-tertiary-gray-500: rgb(66, 66, 66);
$ref-tertiary-gray-600: rgb(43, 43, 43);
$ref-tertiary-gray-700: rgb(15, 15, 15);
$ref-button-md: 6px 24px;
$ref-button-md-icon: 6px 24px 6px 16px;
$ref-button-sm: 6px 8px;
$ref-button-sm-icon: 6px 8px 6px 4px;
$ref-button-segmented: 4px 12px;
$ref-button-text-md: 6px 16px;
$ref-button-text-md-icon: 6px 16px 6px 8px;
$ref-button-text-sm: 6px 8px;
$ref-button-text-sm-icon: 6px 8px 6px 4px;
$ref-button-text-xs: 4px 6px;
$ref-button-text-xs-icon: 4px 6px 4px 4px;
$ref-button-mobile-md: 8px 16px;
$ref-button-mobile-md-icon: 8px 16px 8px 8px;
$ref-button-mobile-sm: 6px 12px;
$ref-button-mobile-sm-icon: 6px 12px 6px 6px;
$ref-sizing-4: 4px;
$ref-sizing-6: 6px;
$ref-sizing-8: 8px;
$ref-sizing-10: 10px;
$ref-sizing-12: 12px;
$ref-sizing-16: 16px;
$ref-sizing-20: 20px;
$ref-sizing-21: 21px;
$ref-sizing-24: 24px;
$ref-sizing-26: 26px;
$ref-sizing-27: 27px;
$ref-sizing-32: 32px;
$ref-sizing-36: 36px;
$ref-sizing-38: 38px;
$ref-sizing-40: 40px;
$ref-sizing-42: 42px;
$ref-sizing-46: 46px;
$ref-sizing-48: 48px;
$ref-sizing-52: 52px;
$ref-sizing-56: 56px;
$ref-sizing-60: 60px;
$ref-sizing-64: 64px;
$ref-sizing-68: 68px;
$ref-sizing-70: 70px;
$ref-sizing-72: 72px;
$ref-sizing-80: 80px;
$ref-sizing-84: 84px;
$ref-sizing-92: 92px;
$ref-sizing-96: 96px;
$ref-sizing-98: 98px;
$ref-sizing-112: 112px;
$ref-sizing-120: 120px;
$ref-sizing-134: 134px;
$ref-sizing-140: 140px;
$ref-sizing-144: 144px;
$ref-sizing-148: 148px;
$ref-sizing-152: 152px;
$ref-sizing-154: 154px;
$ref-sizing-156: 156px;
$ref-sizing-166: 166px;
$ref-sizing-168: 168px;
$ref-sizing-170: 170px;
$ref-sizing-180: 180px;
$ref-sizing-181: 181px;
$ref-sizing-182: 182px;
$ref-sizing-184: 184px;
$ref-sizing-185: 185px;
$ref-sizing-192: 192px;
$ref-sizing-195: 195px;
$ref-sizing-200: 200px;
$ref-sizing-208: 208px;
$ref-sizing-210: 210px;
$ref-sizing-215: 215px;
$ref-sizing-224: 224px;
$ref-sizing-236: 236px;
$ref-sizing-240: 240px;
$ref-sizing-244: 244px;
$ref-sizing-256: 256px;
$ref-sizing-276: 276px;
$ref-sizing-284: 284px;
$ref-sizing-286: 286px;
$ref-sizing-296: 296px;
$ref-sizing-300: 300px;
$ref-sizing-324: 324px;
$ref-sizing-326: 326px;
$ref-sizing-332: 332px;
$ref-sizing-342: 342px;
$ref-sizing-350: 350px;
$ref-sizing-360: 360px;
$ref-sizing-392: 392px;
$ref-sizing-396: 396px;
$ref-sizing-398: 398px;
$ref-sizing-400: 400px;
$ref-sizing-417: 417px;
$ref-sizing-424: 424px;
$ref-sizing-436: 436px;
$ref-sizing-443: 443px;
$ref-sizing-460: 460px;
$ref-sizing-496: 496px;
$ref-sizing-535: 535px;
$ref-sizing-544: 544px;
$ref-sizing-548: 548px;
$ref-sizing-559: 559px;
$ref-sizing-564: 564px;
$ref-sizing-568: 568px;
$ref-sizing-576: 576px;
$ref-sizing-580: 580px;
$ref-sizing-600: 600px;
$ref-sizing-636: 636px;
$ref-sizing-640: 640px;
$ref-sizing-644: 644px;
$ref-sizing-660: 660px;
$ref-sizing-661: 661px;
$ref-sizing-668: 668px;
$ref-sizing-676: 676px;
$ref-sizing-740: 740px;
$ref-sizing-878: 878px;
$ref-sizing-1344: 1344px;
$ref-sizing-1360: 1360px;
$ref-sizing-1376: 1376px;
$ref-sizing-1440: 1440px;
$border-radius-1: 1px;
$border-radius-2: 2px;
$border-radius-3: 3px;
$border-radius-4: 4px;
$border-radius-8: 8px;
$border-radius-12: 12px;
$border-radius-16: 16px;
$border-radius-20: 20px;
$border-radius-24: 24px;
$border-radius-100: 100px;
$border-width-1: 1px;
$border-width-2: 2px;
$border-width-4: 4px;
$border-width-none: 0px;
$border-width-05: 0.5px;
$border-width-08: 0.8px;
$border-width-1-6: 1.6 px;
$opacity-10: 10%;
$opacity-20: 20%;
$opacity-30: 30%;
$opacity-35: 35;
$opacity-50: 50%;
$opacity-90: 90%;
$font-families-proxima-nova: Proxima Nova;
$letter-spacing-0: 0px;
$letter-spacing-01: 0.1px;
$letter-spacing-015: 0.15px;
$letter-spacing-025: 0.25px;
$letter-spacing-04: 0.4px;
$letter-spacing-05: 0.5px;
$paragraph-spacing-20: 20px;
$paragraph-spacing-24: 24px;
$paragraph-spacing-26: 26px;
$paragraph-spacing-28: 28px;
$paragraph-spacing-32: 32px;
$paragraph-spacing-36: 36px;
$paragraph-spacing-40: 40px;
$paragraph-spacing-44: 44px;
$paragraph-spacing-48: 48px;
$paragraph-spacing-54: 54px;
$paragraph-spacing-58: 58px;
$paragraph-spacing-64: 64px;
$paragraph-spacing-72: 72px;
$font-weights-regular: Regular;
$font-weights-semibold: Semibold;
$font-weights-bold: Bold;
$typography-body-lg-font-family: Proxima Nova;
$typography-body-lg-font-weight: 400;
$typography-body-lg-line-height: 24px;
$typography-body-lg-font-size: 16px;
$typography-body-lg-paragraph-spacing: 28px;
$typography-body-lg-letter-spacing: 0.15px;
$typography-body-md-font-family: Proxima Nova;
$typography-body-md-font-weight: 400;
$typography-body-md-line-height: 20px;
$typography-body-md-font-size: 14px;
$typography-body-md-paragraph-spacing: 28px;
$typography-body-md-letter-spacing: 0.25px;
$typography-body-sm-font-family: Proxima Nova;
$typography-body-sm-font-weight: 400;
$typography-body-sm-line-height: 16px;
$typography-body-sm-font-size: 12px;
$typography-body-sm-letter-spacing: 0.4px;
$typography-body-sm-paragraph-spacing: 24px;
$typography-body-xs-font-family: Proxima Nova;
$typography-body-xs-font-weight: 400;
$typography-body-xs-line-height: 15px;
$typography-body-xs-font-size: 10px;
$typography-body-xs-letter-spacing: 0px;
$typography-label-lg-font-family: Proxima Nova;
$typography-label-lg-font-weight: 600;
$typography-label-lg-line-height: 20px;
$typography-label-lg-font-size: 14px;
$typography-label-lg-paragraph-spacing: 28px;
$typography-label-lg-letter-spacing: 0.1px;
$typography-label-md-font-family: Proxima Nova;
$typography-label-md-font-weight: 600;
$typography-label-md-line-height: 16px;
$typography-label-md-font-size: 12px;
$typography-label-md-letter-spacing: 0.4px;
$typography-label-md-paragraph-spacing: 24px;
$typography-label-sm-font-family: Proxima Nova;
$typography-label-sm-font-weight: 600;
$typography-label-sm-line-height: 16px;
$typography-label-sm-font-size: 11px;
$typography-label-sm-letter-spacing: 0.4px;
$typography-label-sm-paragraph-spacing: 20px;
$typography-label-xl-font-family: Proxima Nova;
$typography-label-xl-font-weight: 600;
$typography-label-xl-line-height: 24px;
$typography-label-xl-font-size: 16px;
$typography-label-xl-letter-spacing: 0.1px;
$typography-label-xl-paragraph-spacing: 32px;
$typography-label-xxl-font-family: Proxima Nova;
$typography-label-xxl-font-weight: 600;
$typography-label-xxl-line-height: 24px;
$typography-label-xxl-font-size: 18px;
$typography-label-xxl-letter-spacing: 0.1px;
$typography-label-xxl-paragraph-spacing: 36px;
$typography-title-lg-font-family: Proxima Nova;
$typography-title-lg-font-weight: 600;
$typography-title-lg-line-height: 28px;
$typography-title-lg-font-size: 22px;
$typography-title-lg-letter-spacing: 0px;
$typography-title-lg-paragraph-spacing: 40px;
$typography-title-md-font-family: Proxima Nova;
$typography-title-md-font-weight: 600;
$typography-title-md-line-height: 24px;
$typography-title-md-font-size: 16px;
$typography-title-md-letter-spacing: 0.15px;
$typography-title-md-paragraph-spacing: 28px;
$typography-title-sm-font-family: Proxima Nova;
$typography-title-sm-font-weight: 600;
$typography-title-sm-line-height: 20px;
$typography-title-sm-font-size: 14px;
$typography-title-sm-letter-spacing: 0.1px;
$typography-title-sm-paragraph-spacing: 26px;
$typography-headline-lg-font-family: Proxima Nova;
$typography-headline-lg-font-weight: 600;
$typography-headline-lg-line-height: 40px;
$typography-headline-lg-font-size: 32px;
$typography-headline-lg-letter-spacing: 0px;
$typography-headline-lg-paragraph-spacing: 54px;
$typography-headline-md-font-family: Proxima Nova;
$typography-headline-md-font-weight: 600;
$typography-headline-md-line-height: 36px;
$typography-headline-md-font-size: 28px;
$typography-headline-md-letter-spacing: 0px;
$typography-headline-md-paragraph-spacing: 48px;
$typography-headline-sm-font-family: Proxima Nova;
$typography-headline-sm-font-weight: 600;
$typography-headline-sm-line-height: 32px;
$typography-headline-sm-font-size: 24px;
$typography-headline-sm-letter-spacing: 0px;
$typography-headline-sm-paragraph-spacing: 44px;
$typography-display-lg-font-family: Proxima Nova;
$typography-display-lg-font-weight: 600;
$typography-display-lg-line-height: 64px;
$typography-display-lg-font-size: 57px;
$typography-display-lg-letter-spacing: 0px;
$typography-display-lg-paragraph-spacing: 72px;
$typography-display-md-font-family: Proxima Nova;
$typography-display-md-font-weight: 600;
$typography-display-md-line-height: 46px;
$typography-display-md-font-size: 45px;
$typography-display-md-letter-spacing: 0px;
$typography-display-md-paragraph-spacing: 64px;
$typography-display-sm-font-family: Proxima Nova;
$typography-display-sm-font-weight: 600;
$typography-display-sm-line-height: 38px;
$typography-display-sm-font-size: 36px;
$typography-display-sm-letter-spacing: 0px;
$typography-display-sm-paragraph-spacing: 58px;
$z1-color: #00000033;
$z1-type: dropShadow;
$z1-x: 0px;
$z1-y: 8px;
$z1-blur: 30px;
$z1-spread: 0px;
$z2-color: #0000004d;
$z2-type: dropShadow;
$z2-x: 0px;
$z2-y: 8px;
$z2-blur: 30px;
$z2-spread: 0px;
$text-case-none: none;
$text-decoration-none: none;
$text-decoration-underline: underline;
$line-heights-label-lg: 20px;
$line-heights-label-md: 16px;
$line-heights-label-sm: 16px;
$line-heights-label-xl: 24px;
$line-heights-title-lg: 28px;
$line-heights-title-md: 24px;
$line-heights-title-sm: 20px;
$line-heights-body-lg: 24px;
$line-heights-body-md: 20px;
$line-heights-body-sm: 16px;
$line-heights-body-xs: 15px;
$line-heights-headline-lg: 40px;
$line-heights-headline-md: 36px;
$line-heights-headline-sm: 32px;
$line-heights-display-lg: 64px;
$line-heights-display-md: 46px;
$line-heights-display-sm: 38px;
$font-size-10: 10px;
$font-size-11: 11px;
$font-size-12: 12px;
$font-size-14: 14px;
$font-size-16: 16px;
$font-size-18: 18px;
$font-size-22: 22px;
$font-size-24: 24px;
$font-size-28: 28px;
$font-size-32: 32px;
$font-size-36: 36px;
$font-size-45: 45px;
$font-size-57: 57px;
$dark-bgd-default: rgb(11, 19, 36);
$dark-bgd-variant: rgb(15, 25, 46);
$dark-srf-default: rgb(21, 33, 56);
$dark-srf-variant: rgb(34, 48, 77);
$dark-on-bgd-srf-1: rgb(255, 255, 255);
$dark-on-bgd-srf-2: rgb(223, 225, 229);
$dark-on-bgd-srf-3: rgb(141, 154, 178);
$dark-on-bgd-srf-4: rgb(68, 84, 117);
$dark-on-bgd-srf-5: rgb(34, 48, 77);
$dark-state-bgd-default-hover: rgb(23, 33, 52);
$dark-state-bgd-default-click: rgb(30, 40, 60);
$dark-state-bgd-default-active: rgb(30, 40, 60);
$dark-state-bgd-variant-hover: rgb(27, 38, 61);
$dark-state-bgd-variant-click: rgb(33, 44, 68);
$dark-state-bgd-variant-active: rgb(33, 44, 68);
$dark-state-srf-default-hover: rgb(32, 45, 69);
$dark-state-srf-default-click: rgb(37, 50, 75);
$dark-state-srf-default-active: rgb(37, 50, 75);
$dark-state-srf-variant-hover: rgb(43, 57, 87);
$dark-state-srf-variant-click: rgb(47, 62, 91);
$dark-state-srf-variant-active: rgb(47, 62, 91);
$dark-state-bgd-srf-container-hover: rgba(89, 105, 137, 0.16);
$dark-state-bgd-srf-container-click: rgba(89, 105, 137, 0.24);
$dark-state-bgd-srf-container-active: rgba(89, 105, 137, 0.24);
$dark-primary: rgb(224, 126, 16);
$dark-on-primary: rgb(255, 255, 255);
$dark-state-primary-hover: rgb(206, 116, 15);
$dark-state-primary-press: rgb(188, 106, 13);
$dark-state-primary-container-hover: rgba(252, 143, 20, 0.08);
$dark-state-primary-container-press: rgba(252, 143, 20, 0.16);
$dark-secondary: rgb(114, 129, 158);
$dark-on-secondary: rgb(255, 255, 255);
$dark-state-secondary-hover: rgb(105, 119, 145);
$dark-state-secondary-press: rgb(96, 108, 133);
$dark-state-secondary-container-hover: rgba(89, 105, 137, 0.16);
$dark-state-secondary-container-press: rgba(89, 105, 137, 0.24);
$dark-nf: rgb(0, 117, 235);
$dark-state-nf-container-hover: rgba(0, 117, 235, 0.16);
$dark-state-nf-container-press: rgba(0, 117, 235, 0.24);
$dark-additional: rgba(89, 105, 137, 0.4);
$dark-additional-edit: rgba(141, 154, 178, 0.4);
$dark-additional-variant: rgba(89, 105, 137, 0.12);
$dark-on-additional: rgb(255, 255, 255);
$dark-tooltip: rgb(255, 255, 255);
$dark-on-tooltip-default: rgb(11, 19, 36);
$dark-on-tooltip-warning: rgb(235, 176, 0);
$dark-on-tooltip-danger: rgb(235, 47, 36);
$dark-overlay: rgba(0, 0, 0, 0.6);
$dark-sys-danger: rgb(235, 47, 36);
$dark-sys-warning: rgb(235, 176, 0);
$dark-sys-success: rgb(97, 185, 30);
$dark-sys-informative: rgb(0, 117, 235);
$dark-on-sys: rgb(255, 255, 255);
$dark-tertiary-pink: rgb(226, 35, 81);
$dark-tertiary-peach: rgb(226, 92, 35);
$dark-tertiary-sand: rgb(229, 183, 25);
$dark-tertiary-lime: rgb(141, 227, 57);
$dark-tertiary-blue: rgb(35, 181, 266);
$dark-tertiary-indigo: rgb(119, 35, 226);
$dark-tertiary-purple: rgb(199, 35, 226);
$dark-tertiary-gray: rgb(158, 158, 158);
$dark-tertiary-container-pink: rgb(59, 9, 20);
$dark-tertiary-container-peach: rgb(59, 24, 9);
$dark-tertiary-container-sand: rgb(56, 44, 6);
$dark-tertiary-container-lime: rgb(36, 59, 15);
$dark-tertiary-container-blue: rgb(9, 47, 59);
$dark-tertiary-container-indigo: rgb(31, 9, 59);
$dark-tertiary-container-purple: rgb(27, 5, 31);
$dark-tertiary-container-gray: rgb(15, 15, 15);
$dark-tertiary-container-pink-variant: rgba(115, 18, 41, 0.1);
$dark-tertiary-container-peach-variant: rgba(115, 47, 18, 0.1);
$dark-tertiary-container-sand-variant: rgba(112, 90, 12, 0.1);
$dark-tertiary-container-lime-variant: rgba(71, 115, 29, 0.1);
$dark-tertiary-container-blue-variant: rgba(18, 92, 115, 0.1);
$dark-tertiary-container-indigo-variant: rgba(60, 18, 115, 0.1);
$dark-tertiary-container-purple-variant: rgba(76, 13, 87, 0.1);
$dark-tertiary-container-gray-variant: rgba(66, 66, 66, 0.1);
$dark-on-tertiary-container-pink: rgb(246, 189, 203);
$dark-on-tertiary-container-peach: rgb(247, 211, 195);
$dark-on-tertiary-container-sand: rgb(245, 231, 184);
$dark-on-tertiary-container-lime: rgb(221, 247, 195);
$dark-on-tertiary-container-blue: rgb(195, 235, 247);
$dark-on-tertiary-container-indigo: rgb(218, 195, 247);
$dark-on-tertiary-container-purple: rgb(240, 195, 247);
$dark-on-tertiary-container-gray: rgb(230, 230, 230);
$dark-on-tertiary-container-pink-variant: rgb(199, 31, 72);
$dark-on-tertiary-container-peach-variant: rgb(199, 81, 31);
$dark-on-tertiary-container-sand-variant: rgb(196, 156, 20);
$dark-on-tertiary-container-lime-variant: rgb(124, 199, 52);
$dark-on-tertiary-container-blue-variant: rgb(31, 160, 199);
$dark-on-tertiary-container-indigo-variant: rgb(105, 31, 199);
$dark-on-tertiary-container-purple-variant: rgb(175, 31, 199);
$dark-on-tertiary-container-gray-variant: rgb(135, 135, 135);
$dark-disabled: 50%;
$dark-table-group-1-border: rgba(89, 105, 137, 100);
$dark-table-group-1-header: rgba(89, 105, 137, 0.5);
$dark-table-group-1-container: rgba(89, 105, 137, 0.1);
$dark-table-group-2-border: rgba(114, 129, 158, 100);
$dark-table-group-2-header: rgba(114, 129, 158, 0.5);
$dark-table-group-2-container: rgba(114, 129, 158, 0.1);
$dark-table-group-3-border: rgba(141, 154, 178, 100);
$dark-table-group-3-header: rgba(141, 154, 178, 0.5);
$dark-table-group-3-container: rgba(141, 154, 178, 0.1);
$dark-gradient-skeleton: rgba(27, 40, 66, 0.8);
$dark-state-sys-danger-hover: rgb(216, 43, 33);
$dark-state-sys-danger-press: rgb(197, 39, 30);
$dark-state-sys-warning-hover: rgb(216, 162, 0);
$dark-state-sys-warning-press: rgb(197, 148, 0);
$dark-state-sys-success-hover: rgb(89, 170, 28);
$dark-state-sys-success-press: rgb(81, 155, 25);
$dark-state-sys-informative-hover: rgb(0, 108, 216);
$dark-state-sys-informative-press: rgb(0, 98, 197);
$dark-heatmap-20: rgba(224, 126, 16, 0.08);
$dark-heatmap-40: rgba(224, 126, 16, 0.2);
$dark-heatmap-60: rgba(224, 126, 16, 0.36);
$dark-heatmap-80: rgba(224, 126, 16, 0.52);
$dark-heatmap-81plus: rgba(224, 126, 16, 0.75);
$dark-on-heatmap: rgb(255, 255, 255);
$dark-selection-off: rgb(27, 40, 66);
$dark-selection-on: rgb(33, 31, 29);
$dark-state-selection-off-hover: rgb(37, 50, 77);
$dark-state-selection-off-press: rgb(42, 56, 83);
$dark-state-selection-on-hover: rgb(51, 40, 28);
$dark-state-selection-on-press: rgb(68, 49, 28);
$dark-design-set-lime-100: rgb(141, 227, 57);
$dark-design-set-lime-200: rgb(124, 199, 52);
$dark-design-set-lime-300: rgb(105, 171, 43);
$dark-design-set-lime-400: rgb(89, 143, 37);
$dark-design-set-lime-500: rgb(71, 115, 29);
$dark-design-set-lime-600: rgb(54, 87, 23);
$dark-design-set-sand-100: rgb(229, 183, 25);
$dark-design-set-sand-200: rgb(196, 156, 20);
$dark-design-set-sand-300: rgb(168, 134, 19);
$dark-design-set-sand-400: rgb(140, 112, 14);
$dark-design-set-sand-500: rgb(112, 90, 12);
$dark-design-set-sand-600: rgb(84, 67, 8);
$dark-design-set-blue-100: rgb(35, 181, 266);
$dark-design-set-blue-200: rgb(31, 160, 199);
$dark-design-set-blue-300: rgb(26, 137, 171);
$dark-design-set-blue-400: rgb(22, 115, 143);
$dark-design-set-blue-500: rgb(18, 92, 115);
$dark-design-set-blue-600: rgb(13, 70, 87);
$dark-design-set-pink-100: rgb(226, 35, 81);
$dark-design-set-pink-200: rgb(199, 31, 72);
$dark-design-set-pink-300: rgb(171, 27, 61);
$dark-design-set-pink-400: rgb(143, 22, 51);
$dark-design-set-pink-500: rgb(115, 18, 41);
$dark-design-set-pink-600: rgb(87, 14, 31);
$dark-design-set-peach-100: rgb(226, 92, 35);
$dark-design-set-peach-200: rgb(199, 81, 31);
$dark-design-set-peach-300: rgb(171, 70, 26);
$dark-design-set-peach-400: rgb(143, 58, 22);
$dark-design-set-peach-500: rgb(115, 47, 18);
$dark-design-set-peach-600: rgb(87, 35, 13);
$dark-design-set-indigo-100: rgb(119, 35, 226);
$dark-design-set-indigo-200: rgb(105, 31, 199);
$dark-design-set-indigo-300: rgb(90, 26, 171);
$dark-design-set-indigo-400: rgb(75, 22, 143);
$dark-design-set-indigo-500: rgb(60, 18, 115);
$dark-design-set-indigo-600: rgb(46, 13, 87);
$dark-design-set-purple-100: rgb(199, 35, 226);
$dark-design-set-purple-200: rgb(175, 31, 199);
$dark-design-set-purple-300: rgb(126, 22, 143);
$dark-design-set-purple-400: rgb(101, 18, 115);
$dark-design-set-purple-500: rgb(76, 13, 87);
$dark-design-set-purple-600: rgb(52, 9, 59);
$dark-design-set-gray-100: rgb(158, 158, 158);
$dark-design-set-gray-200: rgb(135, 135, 135);
$dark-design-set-gray-300: rgb(112, 112, 112);
$dark-design-set-gray-400: rgb(89, 89, 89);
$dark-design-set-gray-500: rgb(66, 66, 66);
$dark-design-set-gray-600: rgb(43, 43, 43);
$dark-submittal: rgb(97, 185, 30);
$dark-state-submittal-container-hover: rgba(97, 185, 30, 0.16);
$dark-state-submittal-container-press: rgba(97, 185, 30, 0.24);
$dark-notification-warning: rgba(235, 176, 0, 0.1);
$dark-table-group-level-1: rgb(27, 40, 66);
$dark-table-group-level-2: rgb(21, 33, 56);
$dark-table-group-level-3: rgb(15, 25, 46);
$dark-table-group-level-4: rgb(11, 19, 36);
$dark-Z0-x: 0px;
$dark-Z0-y: 4px;
$dark-Z0-blur: 40px;
$dark-Z0-spread: 0px;
$dark-Z0-color: rgba(0, 0, 0, 0.4);
$dark-Z0-type: dropShadow;
$light-bgd-default: rgb(247, 247, 247);
$light-bgd-variant: rgb(240, 240, 240);
$light-srf-default: rgb(255, 255, 255);
$light-srf-variant: rgb(240, 240, 240);
$light-on-bgd-srf-1: rgb(11, 19, 36);
$light-on-bgd-srf-2: rgb(34, 48, 77);
$light-on-bgd-srf-3: rgb(89, 105, 137);
$light-on-bgd-srf-4: rgb(171, 180, 199);
$light-on-bgd-srf-5: rgb(204, 209, 219);
$light-state-bgd-default-hover: rgb(234, 236, 238);
$light-state-bgd-default-click: rgb(222, 224, 229);
$light-state-bgd-default-active: rgb(222, 224, 229);
$light-state-bgd-variant-hover: rgb(228, 229, 232);
$light-state-bgd-variant-click: rgb(216, 218, 224);
$light-state-bgd-variant-active: rgb(216, 218, 224);
$light-state-srf-default-hover: rgb(228, 231, 236);
$light-state-srf-default-click: rgb(215, 219, 227);
$light-state-srf-default-active: rgb(215, 219, 227);
$light-state-srf-variant-hover: rgb(216, 218, 224);
$light-state-srf-variant-click: rgb(204, 208, 215);
$light-state-srf-variant-active: rgb(204, 208, 215);
$light-state-bgd-srf-container-hover: rgba(89, 105, 137, 0.08);
$light-state-bgd-srf-container-click: rgba(89, 105, 137, 0.16);
$light-state-bgd-srf-container-active: rgba(89, 105, 137, 0.16);
$light-primary: rgb(252, 143, 20);
$light-on-primary: rgb(255, 255, 255);
$light-state-primary-hover: rgb(232, 132, 18);
$light-state-primary-press: rgb(212, 120, 17);
$light-state-primary-container-hover: rgba(252, 143, 20, 0.08);
$light-state-primary-container-press: rgba(252, 143, 20, 0.16);
$light-secondary: rgb(89, 105, 137);
$light-on-secondary: rgb(255, 255, 255);
$light-state-secondary-hover: rgb(82, 97, 126);
$light-state-secondary-press: rgb(75, 88, 115);
$light-state-secondary-container-hover: rgba(89, 105, 137, 0.16);
$light-state-secondary-container-press: rgba(89, 105, 137, 0.24);
$light-nf: rgb(0, 117, 235);
$light-state-nf-container-hover: rgba(0, 117, 235, 0.16);
$light-state-nf-container-press: rgba(0, 117, 235, 0.24);
$light-additional: rgba(89, 105, 137, 0.2);
$light-additional-edit: rgba(49, 65, 97, 0.4);
$light-additional-variant: rgba(89, 105, 137, 0.06);
$light-on-additional: rgb(11, 19, 36);
$light-tooltip: rgb(11, 19, 36);
$light-on-tooltip-default: rgb(255, 255, 255);
$light-on-tooltip-warning: rgb(255, 196, 18);
$light-on-tooltip-danger: rgb(255, 69, 58);
$light-overlay: rgba(0, 0, 0, 0.2);
$light-sys-danger: rgb(255, 69, 58);
$light-sys-warning: rgb(235, 176, 0);
$light-sys-success: rgb(123, 215, 50);
$light-sys-informative: rgb(18, 136, 255);
$light-on-sys: rgb(255, 255, 255);
$light-tertiary-pink: rgb(226, 35, 81);
$light-tertiary-peach: rgb(226, 92, 35);
$light-tertiary-sand: rgb(229, 183, 25);
$light-tertiary-lime: rgb(141, 227, 57);
$light-tertiary-blue: rgb(35, 181, 266);
$light-tertiary-indigo: rgb(119, 35, 226);
$light-tertiary-purple: rgb(199, 35, 226);
$light-tertiary-gray: rgb(158, 158, 158);
$light-tertiary-container-pink: rgb(246, 189, 203);
$light-tertiary-container-peach: rgb(247, 211, 195);
$light-tertiary-container-sand: rgb(245, 231, 184);
$light-tertiary-container-lime: rgb(221, 247, 195);
$light-tertiary-container-blue: rgb(195, 235, 247);
$light-tertiary-container-indigo: rgb(218, 195, 247);
$light-tertiary-container-purple: rgb(240, 195, 247);
$light-tertiary-container-gray: rgb(230, 230, 230);
$light-tertiary-container-pink-variant: rgba(246, 189, 203, 0.2);
$light-tertiary-container-peach-variant: rgba(247, 211, 195, 0.2);
$light-tertiary-container-sand-variant: rgba(245, 231, 184, 0.2);
$light-tertiary-container-lime-variant: rgba(221, 247, 195, 0.2);
$light-tertiary-container-blue-variant: rgba(195, 235, 247, 0.2);
$light-tertiary-container-indigo-variant: rgba(218, 195, 247, 0.2);
$light-tertiary-container-purple-variant: rgba(240, 195, 247, 0.2);
$light-tertiary-container-gray-variant: rgba(230, 230, 230, 0.2);
$light-on-tertiary-container-pink: rgb(115, 18, 41);
$light-on-tertiary-container-peach: rgb(115, 47, 18);
$light-on-tertiary-container-sand: rgb(112, 90, 12);
$light-on-tertiary-container-lime: rgb(71, 115, 29);
$light-on-tertiary-container-blue: rgb(18, 92, 115);
$light-on-tertiary-container-indigo: rgb(60, 18, 115);
$light-on-tertiary-container-purple: rgb(76, 13, 87);
$light-on-tertiary-container-gray: rgb(66, 66, 66);
$light-on-tertiary-container-pink-variant: rgb(171, 27, 61);
$light-on-tertiary-container-pech-variant: rgb(171, 70, 26);
$light-on-tertiary-container-sand-variant: rgb(168, 134, 19);
$light-on-tertiary-container-lime-variant: rgb(105, 171, 43);
$light-on-tertiary-container-blue-variant: rgb(26, 137, 171);
$light-on-tertiary-container-indigo-variant: rgb(90, 26, 171);
$light-on-tertiary-container-purple-variant: rgb(126, 22, 143);
$light-on-tertiary-container-gray-variant: rgb(112, 112, 112);
$light-disabled: 50%;
$light-table-group-1-border: rgba(204, 209, 219, 100);
$light-table-group-1-header: rgba(204, 209, 219, 0.5);
$light-table-group-1-container: rgba(204, 209, 219, 0.1);
$light-table-group-2-border: rgba(171, 180, 199, 100);
$light-table-group-2-header: rgba(171, 180, 199, 0.5);
$light-table-group-2-container: rgba(171, 180, 199, 0.1);
$light-table-group-3-border: rgba(141, 154, 178, 100);
$light-table-group-3-header: rgba(141, 154, 178, 0.5);
$light-table-group-3-container: rgba(141, 154, 178, 0.1);
$light-gradient-skeleton: rgba(223, 225, 229, 0.7);
$light-state-sys-danger-hover: rgb(235, 63, 53);
$light-state-sys-danger-press: rgb(214, 58, 49);
$light-state-sys-warning-hover: rgb(216, 162, 0);
$light-state-sys-warning-press: rgb(197, 148, 0);
$light-state-sys-success-hover: rgb(113, 198, 46);
$light-state-sys-success-press: rgb(103, 181, 42);
$light-state-sys-informative-hover: rgb(17, 125, 235);
$light-state-sys-informative-press: rgb(15, 114, 214);
$light-heatmap-20: rgba(224, 126, 16, 0.08);
$light-heatmap-40: rgba(224, 126, 16, 0.2);
$light-heatmap-60: rgba(224, 126, 16, 0.36);
$light-heatmap-80: rgba(224, 126, 16, 0.52);
$light-heatmap-81plus: rgba(224, 126, 16, 0.75);
$light-on-heatmap: rgb(11, 19, 36);
$light-selection-off: rgb(223, 225, 229);
$light-selection-on: rgb(252, 219, 181);
$light-state-selection-off-hover: rgb(202, 206, 214);
$light-state-selection-off-press: rgb(191, 196, 207);
$light-state-selection-on-hover: rgb(252, 213, 168);
$light-state-selection-on-press: rgb(252, 207, 155);
$light-design-set-lime-100: rgb(141, 227, 57);
$light-design-set-lime-200: rgb(151, 232, 72);
$light-design-set-lime-300: rgb(174, 237, 111);
$light-design-set-lime-400: rgb(197, 242, 153);
$light-design-set-lime-500: rgb(221, 247, 195);
$light-design-set-lime-600: rgb(221, 247, 195);
$light-design-set-sand-100: rgb(229, 183, 25);
$light-design-set-sand-200: rgb(235, 196, 63);
$light-design-set-sand-300: rgb(240, 209, 103);
$light-design-set-sand-400: rgb(245, 222, 144);
$light-design-set-sand-500: rgb(245, 231, 184);
$light-design-set-sand-600: rgb(245, 231, 184);
$light-design-set-blue-100: rgb(35, 181, 266);
$light-design-set-blue-200: rgb(72, 195, 232);
$light-design-set-blue-300: rgb(111, 208, 237);
$light-design-set-blue-400: rgb(153, 221, 242);
$light-design-set-blue-500: rgb(195, 235, 247);
$light-design-set-blue-600: rgb(195, 235, 247);
$light-design-set-pink-100: rgb(226, 35, 81);
$light-design-set-pink-200: rgb(229, 57, 98);
$light-design-set-pink-300: rgb(235, 101, 133);
$light-design-set-pink-400: rgb(241, 145, 168);
$light-design-set-pink-500: rgb(246, 189, 203);
$light-design-set-pink-600: rgb(246, 189, 203);
$light-design-set-peach-100: rgb(226, 92, 35);
$light-design-set-peach-200: rgb(232, 120, 72);
$light-design-set-peach-300: rgb(237, 149, 111);
$light-design-set-peach-400: rgb(242, 180, 153);
$light-design-set-peach-500: rgb(247, 211, 195);
$light-design-set-peach-600: rgb(247, 211, 195);
$light-design-set-indigo-100: rgb(119, 35, 226);
$light-design-set-indigo-200: rgb(142, 72, 232);
$light-design-set-indigo-300: rgb(167, 111, 237);
$light-design-set-indigo-400: rgb(192, 153, 242);
$light-design-set-indigo-500: rgb(218, 195, 247);
$light-design-set-indigo-600: rgb(218, 195, 247);
$light-design-set-purple-100: rgb(199, 35, 226);
$light-design-set-purple-200: rgb(210, 72, 232);
$light-design-set-purple-300: rgb(220, 111, 237);
$light-design-set-purple-400: rgb(230, 153, 242);
$light-design-set-purple-500: rgb(240, 195, 247);
$light-design-set-purple-600: rgb(240, 195, 247);
$light-design-set-gray-100: rgb(158, 158, 158);
$light-design-set-gray-200: rgb(176, 176, 176);
$light-design-set-gray-300: rgb(194, 194, 194);
$light-design-set-gray-400: rgb(212, 212, 212);
$light-design-set-gray-500: rgb(230, 230, 230);
$light-design-set-gray-600: rgb(230, 230, 230);
$light-submittal: rgb(97, 185, 30);
$light-state-submittal-container-hover: rgba(97, 185, 30, 0.16);
$light-state-submittal-container-press: rgba(97, 185, 30, 0.24);
$light-notification-warning: rgba(235, 176, 0, 0.1);
$light-table-group-level-1: rgb(223, 225, 229);
$light-table-group-level-2: #e5e7e9;
$light-table-group-level-3: rgb(240, 240, 240);
$light-table-group-level-4: rgb(247, 247, 247);
$light-Z0-x: 0;
$light-Z0-y: 4;
$light-Z0-blur: 24;
$light-Z0-spread: 0;
$light-Z0-color: rgba(11, 19, 36, 0.08);
$light-Z0-type: dropShadow;
