.button {
  border-radius: 3px;
  font-size: 12px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 30px;

  &-primary {
    background: #9aaacb;
    border: 1px solid #9aaacb;
    color: #fff !important;
  }

  &-outline {
    background: transparent;
    border: 1px solid #c3cdd4;
    color: #38455e !important;
  }

  &-mh {
    margin: 0 10px;
  }

  &-icon {
    flex-direction: row;
  }

  &-sm {
    width: 120px;
    height: 30px;
  }

  &-md {
    width: 148px;
  }

  &-lr {
    width: 158px;
  }
  &-x {
    width: 284px;
  }
}

button:disabled,
button[disabled] {
  opacity: 0.5;
  color: #fff;
}
