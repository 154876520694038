@import '../../../assets/styles/_variablesDesignTokes';
@import '../../../assets/styles/highResolution';

.b-button-dark,
.b-button-light {
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: $border-radius-2;
  position: relative;
  transition: all 200ms ease-out;
  width: max-content;
  box-sizing: border-box;
  border: none;
  user-select: none;

  .highResolution & {
    @media screen and (min-width: $resolution-2k) {
      border-radius: calculate2kValue($border-radius-2);
    }

    @media screen and (min-width: $resolution-4k) {
      border-radius: calculate4kValue($border-radius-2);
    }
  }

  &_customIcon {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &.-disabled {
    pointer-events: none;
  }

  &.-loading {
    pointer-events: none;

    .buttonTitle,
    .buttonIcon {
      opacity: 0;
    }
  }

  &.-isRightPositionIcon {
    &.-md {
      padding: $ref-spacing-6 0 $ref-spacing-6 $ref-spacing-24 !important;
    }
  }

  // Styles of the button by general size
  &.-xs {
    gap: $ref-spacing-4;
    height: $ref-sizing-24;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        gap: calculate2kValue($ref-spacing-4);
        height: calculate2kValue($ref-sizing-24);
      }

      @media screen and (min-width: $resolution-4k) {
        gap: calculate4kValue($ref-spacing-4);
        height: calculate4kValue($ref-sizing-24);
      }
    }
  }

  &.-sm {
    gap: $ref-spacing-4;
    height: 28px;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        gap: calculate2kValue($ref-spacing-4);
        height: calculate2kValue(28px);
      }

      @media screen and (min-width: $resolution-4k) {
        gap: calculate4kValue($ref-spacing-4);
        height: calculate4kValue(28px);
      }
    }
  }

  &.-md {
    gap: $ref-spacing-8;
    height: $ref-sizing-32;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        gap: calculate2kValue($ref-spacing-8);
        height: calculate2kValue($ref-sizing-32);
      }

      @media screen and (min-width: $resolution-4k) {
        gap: calculate4kValue($ref-spacing-8);
        height: calculate4kValue($ref-sizing-32);
      }
    }
  }

  &.-mobile.-md {
    gap: $ref-spacing-8;
    height: $ref-sizing-40;
  }

  &.-mobile.-sm {
    gap: $ref-spacing-6;
    height: $ref-sizing-36;
  }

  &.-mobile.-xs {
    gap: $ref-spacing-6;
    height: $ref-sizing-32;
  }

  &.-filter-mobile {
    gap: $ref-spacing-8;
    height: $ref-spacing-40;
  }

  .buttonTitle {
    white-space: nowrap;
  }

  // Icon size by button size

  .buttonIcon {
    &.-xs,
    &.-sm {
      width: 12px;
      height: 12px;

      .highResolution & {
        @media screen and (min-width: $resolution-2k) {
          width: calculate2kValue($ref-sizing-12);
          height: calculate2kValue($ref-sizing-12);
        }

        @media screen and (min-width: $resolution-4k) {
          width: calculate4kValue($ref-sizing-12);
          height: calculate4kValue($ref-sizing-12);
        }
      }
    }

    &.-md {
      width: 16px;
      height: 16px;

      .highResolution & {
        @media screen and (min-width: $resolution-2k) {
          width: calculate2kValue($ref-sizing-16);
          height: calculate2kValue($ref-sizing-16);
        }

        @media screen and (min-width: $resolution-4k) {
          width: calculate4kValue($ref-sizing-16);
          height: calculate4kValue($ref-sizing-16);
        }
      }
    }

    &.-mobile.-md {
      width: $ref-sizing-24;
      height: $ref-sizing-24;
    }

    &.-mobile.-sm {
      width: $ref-sizing-24;
      height: $ref-sizing-24;
    }

    &.-mobile.-xs {
      width: $ref-sizing-20;
      height: $ref-sizing-20;
    }

    &.-xs,
    &.-sm,
    &.-md,
    &.-mobile {
      svg {
        max-width: 100%;
        max-height: 100%;
      }
    }
  }

  .isRightPositionIcon {
    width: $ref-sizing-32 !important;
    height: $ref-sizing-32 !important;
    border-left: $border-width-1 solid rgba(0, 0, 0, 0.16);
  }

  // Font size

  &_primary,
  &_secondary,
  &_tertiary,
  &_accept,
  &_decline {
    .buttonTitle {
      white-space: nowrap;
      &.-xs,
      &.-sm {
        font-family: $typography-label-md-font-family;
        font-weight: $typography-label-md-font-weight;
        line-height: $typography-label-md-line-height;
        font-size: $typography-label-md-font-size;
        letter-spacing: $typography-label-md-letter-spacing;

        .highResolution & {
          @media screen and (min-width: $resolution-2k) {
            font-size: calculate2kValue($typography-label-md-font-size);
            line-height: calculate2kValue($typography-label-md-line-height);
            letter-spacing: calculate2kValue($typography-label-md-letter-spacing);
          }

          @media screen and (min-width: $resolution-4k) {
            font-size: calculate4kValue($typography-label-md-font-size);
            line-height: calculate4kValue($typography-label-md-line-height);
            letter-spacing: calculate4kValue($typography-label-md-letter-spacing);
          }
        }
      }

      &.-md {
        font-family: $typography-label-lg-font-family;
        font-weight: $typography-label-lg-font-weight;
        line-height: $typography-label-lg-line-height;
        font-size: $typography-label-lg-font-size;
        letter-spacing: $typography-label-lg-letter-spacing;

        .highResolution & {
          @media screen and (min-width: $resolution-2k) {
            font-size: calculate2kValue($typography-label-lg-font-size);
            line-height: calculate2kValue($typography-label-lg-line-height);
            letter-spacing: calculate2kValue($typography-label-lg-letter-spacing);
          }

          @media screen and (min-width: $resolution-4k) {
            font-size: calculate4kValue($typography-label-lg-font-size);
            line-height: calculate4kValue($typography-label-lg-line-height);
            letter-spacing: calculate4kValue($typography-label-lg-letter-spacing);
          }
        }
      }

      &.-mobile {
        font-family: $typography-label-xl-font-family;
        font-weight: $typography-label-xl-font-weight;
        line-height: $typography-label-xl-line-height;
        font-size: $typography-label-xl-font-size;
        letter-spacing: $typography-label-xl-letter-spacing;
        padding: 0;
      }
    }
  }

  &_text-plain,
  &_text-activated,
  &_filter-plain,
  &_filter-activated,
  &_selection-plain,
  &_selection-activated {
    .buttonTitle {
      &.-xs,
      &.-sm {
        font-family: $typography-body-sm-font-family;
        font-weight: $typography-body-sm-font-weight;
        line-height: $typography-body-sm-line-height;
        font-size: $typography-body-sm-font-size;
        letter-spacing: $typography-body-sm-letter-spacing;

        .highResolution & {
          @media screen and (min-width: $resolution-2k) {
            font-size: calculate2kValue($typography-body-sm-font-size);
            line-height: calculate2kValue($typography-body-sm-line-height);
            letter-spacing: calculate2kValue($typography-body-sm-letter-spacing);
          }

          @media screen and (min-width: $resolution-4k) {
            font-size: calculate4kValue($typography-body-sm-font-size);
            line-height: calculate4kValue($typography-body-sm-line-height);
            letter-spacing: calculate4kValue($typography-body-sm-letter-spacing);
          }
        }
      }

      &.-md {
        font-family: $typography-body-md-font-family;
        font-weight: $typography-body-md-font-weight;
        line-height: $typography-body-md-line-height;
        font-size: $typography-body-md-font-size;
        letter-spacing: $typography-body-md-letter-spacing;

        .highResolution & {
          @media screen and (min-width: $resolution-2k) {
            font-size: calculate2kValue($typography-body-md-font-size);
            line-height: calculate2kValue($typography-body-md-line-height);
            letter-spacing: calculate2kValue($typography-body-md-letter-spacing);
          }

          @media screen and (min-width: $resolution-4k) {
            font-size: calculate4kValue($typography-body-md-font-size);
            line-height: calculate4kValue($typography-body-md-line-height);
            letter-spacing: calculate4kValue($typography-body-md-letter-spacing);
          }
        }
      }

      &.-mobile.-md {
        font-family: $typography-label-xl-font-family;
        font-weight: $typography-label-xl-font-weight;
        line-height: $typography-label-xl-line-height;
        font-size: $typography-label-xl-font-size;
        letter-spacing: $typography-label-xl-letter-spacing;
      }

      &.-mobile.-sm {
        font-family: $typography-label-xl-font-family;
        font-weight: $typography-label-xl-font-weight;
        line-height: $typography-label-xl-line-height;
        font-size: $typography-label-xl-font-size;
        letter-spacing: $typography-label-xl-letter-spacing;
      }

      &.-mobile.-xs {
        font-family: $typography-label-lg-font-family;
        font-weight: $typography-label-lg-font-weight;
        line-height: $typography-label-lg-line-height;
        font-size: $typography-label-lg-font-size;
        letter-spacing: $typography-label-lg-letter-spacing;
      }
    }
  }

  &_text-plain,
  &_text-activated,
  &_selection-plain,
  &_selection-activated {
    .buttonTitle {
      &.-mobile {
        font-family: $typography-label-xl-font-family;
        font-weight: $typography-label-xl-font-weight;
        line-height: $typography-label-xl-line-height;
        font-size: $typography-label-xl-font-size;
        letter-spacing: $typography-label-xl-letter-spacing;
        padding: 0;
      }
    }
  }

  &_filter-plain,
  &_filter-activated {
    .buttonTitle {
      &.-mobile {
        font-family: $typography-body-lg-font-family;
        font-weight: $typography-body-lg-font-weight;
        line-height: $typography-body-lg-line-height;
        font-size: $typography-body-lg-font-size;
        letter-spacing: $typography-body-lg-letter-spacing;
        padding: 0;
      }
    }
  }

  // CustomButton size

  &.-sm {
    padding: $ref-button-sm;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-8);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-8);
      }
    }
  }

  &.-md {
    padding: $ref-button-md;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-24);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-24);
      }
    }
  }

  &_primary,
  &_secondary,
  &_tertiary,
  &_accept,
  &_decline {
    &.-mobile.-md {
      padding: $ref-spacing-8 $ref-spacing-16;
    }

    &.-mobile.-sm {
      padding: $ref-spacing-6 $ref-spacing-12;
    }
  }

  &_text-plain,
  &_text-activated,
  &_selection-plain,
  &_selection-activated {
    &.-mobile.-md {
      padding: $ref-spacing-8 $ref-spacing-16;
    }

    &.-mobile.-sm {
      padding: $ref-spacing-6 $ref-spacing-12;
    }

    &.-mobile.-sm {
      padding: $ref-spacing-6 $ref-spacing-12;
    }
  }

  &_filter-plain,
  &_filter-activated {
    &.-mobile.-md {
      padding: $ref-spacing-8 $ref-spacing-16;
    }

    &.-mobile.-sm {
      padding: $ref-spacing-6 $ref-spacing-12;
    }

    &.-mobile.-sm {
      padding: $ref-spacing-6 $ref-spacing-12;
    }
  }

  // CustomButton size with icon

  &.-sm-icon {
    padding: $ref-button-sm-icon;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-8) calculate2kValue($ref-sizing-6)
          calculate2kValue($ref-sizing-4);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-8) calculate4kValue($ref-sizing-6)
          calculate4kValue($ref-sizing-4);
      }
    }
  }

  &.-md-icon {
    padding: $ref-button-md-icon;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-24) calculate2kValue($ref-sizing-6)
          calculate2kValue($ref-sizing-16);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-24) calculate4kValue($ref-sizing-6)
          calculate4kValue($ref-sizing-16);
      }
    }
  }

  // Text button size

  &.-text-xs {
    padding: $ref-button-text-xs;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-4) calculate2kValue($ref-sizing-6);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-4) calculate4kValue($ref-sizing-6);
      }
    }
  }

  &.-filter-xs {
    padding: $ref-button-text-xs;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-4) calculate2kValue($ref-sizing-6);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-4) calculate4kValue($ref-sizing-6);
      }
    }
  }

  &.-text-sm {
    padding: $ref-button-text-sm;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-8);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-8);
      }
    }
  }

  &.-filter-sm {
    padding: $ref-button-text-sm;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-8);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-8);
      }
    }
  }

  &.-text-md {
    padding: $ref-button-text-md;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-16);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-16);
      }
    }
  }

  &.-filter-md {
    padding: $ref-button-text-md;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-16);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-16);
      }
    }
  }

  // Text button with icon size

  &.-text-xs-icon {
    padding: $ref-button-text-xs-icon;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-4) calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-4)
          calculate2kValue($ref-sizing-4);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-4) calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-4)
          calculate4kValue($ref-sizing-4);
      }
    }
  }

  &.-filter-xs-icon {
    padding: $ref-button-text-xs-icon;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-4) calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-4)
          calculate2kValue($ref-sizing-4);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-4) calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-4)
          calculate4kValue($ref-sizing-4);
      }
    }
  }

  &.-text-sm-icon {
    padding: $ref-button-text-sm-icon;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-8) calculate2kValue($ref-sizing-6)
          calculate2kValue($ref-sizing-4);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-8) calculate4kValue($ref-sizing-6)
          calculate4kValue($ref-sizing-4);
      }
    }
  }

  &.-filter-sm-icon {
    padding: $ref-button-text-sm-icon;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-8) calculate2kValue($ref-sizing-6)
          calculate2kValue($ref-sizing-4);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-8) calculate4kValue($ref-sizing-6)
          calculate4kValue($ref-sizing-4);
      }
    }
  }

  &.-text-md-icon {
    padding: $ref-button-text-md-icon;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-16) calculate2kValue($ref-sizing-6)
          calculate2kValue($ref-sizing-8);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-16) calculate4kValue($ref-sizing-6)
          calculate4kValue($ref-sizing-8);
      }
    }
  }

  &.-filter-md-icon {
    padding: $ref-button-text-md-icon;

    .highResolution & {
      @media screen and (min-width: $resolution-2k) {
        padding: calculate2kValue($ref-sizing-6) calculate2kValue($ref-sizing-16) calculate2kValue($ref-sizing-6)
          calculate2kValue($ref-sizing-8);
      }

      @media screen and (min-width: $resolution-4k) {
        padding: calculate4kValue($ref-sizing-6) calculate4kValue($ref-sizing-16) calculate4kValue($ref-sizing-6)
          calculate4kValue($ref-sizing-8);
      }
    }

    &.-isHiddenTitle {
      padding-right: $ref-spacing-8;

      .highResolution & {
        @media screen and (min-width: $resolution-2k) {
          padding-right: calculate2kValue($ref-spacing-8);
        }

        @media screen and (min-width: $resolution-4k) {
          padding-right: calculate4kValue($ref-spacing-8);
        }
      }
    }
  }

  &.-filter-customSize-icon {
    &.-isHiddenTitle {
      padding: $ref-spacing-2;
      border-radius: $border-radius-2;

      .highResolution & {
        @media screen and (min-width: $resolution-2k) {
          padding: calculate2kValue($ref-spacing-2);
          border-radius: calculate2kValue($border-radius-2);
        }

        @media screen and (min-width: $resolution-4k) {
          padding: calculate4kValue($ref-spacing-2);
          border-radius: calculate4kValue($border-radius-2);
        }
      }
    }
  }

  &.-mobile.-lg {
    padding: $ref-spacing-8 $ref-spacing-16;
  }

  &.-mobile.-lg {
    padding: $ref-spacing-6 $ref-spacing-12;
  }

  &.-mobile.-xs {
    padding: $ref-spacing-6 $ref-spacing-12;
  }

  &.-text-mobile-icon.-md {
    padding: $ref-sizing-10 $ref-spacing-16 $ref-sizing-10 $ref-spacing-8;
  }

  &.-text-mobile-icon.-sm {
    padding: $ref-sizing-8 $ref-spacing-12 $ref-sizing-8 $ref-spacing-6;
  }

  &.-text-mobile-icon.-xs {
    padding: $ref-sizing-6 $ref-spacing-12 $ref-sizing-6 $ref-spacing-6;
  }

  &.-mobile-icon.-md {
    padding: $ref-sizing-10 $ref-spacing-16 $ref-sizing-10 $ref-spacing-8;
  }

  &.-mobile-icon.-sm {
    padding: $ref-sizing-8 $ref-spacing-12 $ref-sizing-8 $ref-spacing-6;
  }

  &.-mobile-icon.-xs {
    padding: $ref-sizing-6 $ref-spacing-12 $ref-sizing-6 $ref-spacing-6;
  }

  &.-filter-mobile-icon {
    padding: $ref-spacing-6 $ref-spacing-16 $ref-spacing-6 $ref-spacing-8;

    &.-isHiddenTitle {
      padding: $ref-sizing-6 $ref-sizing-8;
    }
  }
}

.b-button-dark {
  display: flex;
  justify-content: center;
  align-items: center;

  &.-disabled {
    opacity: $dark-disabled;
  }

  // CustomButton type

  &_primary {
    background-color: $dark-primary;

    &:hover {
      background-color: $dark-state-primary-hover;
    }

    &:active,
    &.-active {
      background-color: $dark-state-primary-press;
    }

    &_title {
      color: $dark-on-primary;
    }

    &_loader_circle {
      stroke: $dark-on-primary !important;
    }
  }

  &_secondary {
    background-color: $dark-secondary;

    &:hover {
      background-color: $dark-state-secondary-hover;
    }

    &:active,
    &.-active {
      background-color: $dark-state-secondary-press;
    }

    &_title {
      color: $dark-on-secondary;
    }

    &_loader_circle {
      stroke: $dark-on-secondary !important;
    }
  }

  &_tertiary {
    background-color: transparent;
    border: 1px solid $dark-secondary;

    &:hover {
      background-color: $dark-state-secondary-container-hover;
    }

    &:active,
    &.-active {
      background-color: $dark-state-secondary-container-press;
    }

    &_title {
      color: $dark-secondary;
    }

    &_loader_circle {
      stroke: $dark-secondary !important;
    }
  }

  &_filter-plain,
  &_text-plain {
    background-color: transparent;

    &:hover {
      background-color: $dark-state-secondary-container-hover;
    }

    &:active,
    &.-active {
      background-color: $dark-state-secondary-container-press;
    }

    &_title {
      color: $dark-secondary;
    }

    &_loader_circle {
      stroke: $dark-secondary !important;
    }
  }

  &_filter-activated,
  &_text-activated {
    background-color: transparent;

    &:hover {
      background-color: $dark-state-primary-container-hover;
    }

    &:active,
    &.-active {
      background-color: $dark-state-primary-container-press;
    }

    &_title {
      color: $dark-primary;
    }

    &_loader_circle {
      stroke: $dark-primary !important;
    }
  }

  &_selection-plain {
    background-color: $dark-selection-off;

    &:hover {
      background-color: $dark-state-selection-off-hover;
    }

    &:active,
    &.-active {
      background-color: $dark-state-selection-off-press;
    }

    &_title {
      color: $dark-secondary;
    }

    &_loader_circle {
      stroke: $dark-secondary !important;
    }
  }

  &_selection-activated {
    background-color: $dark-selection-on;
    border: 1px solid $dark-primary;

    &:hover {
      background-color: $dark-state-selection-on-hover;
    }

    &:active,
    &.-active {
      background-color: $dark-state-selection-on-press;
    }

    &_title {
      color: $dark-primary;
    }

    &_loader_circle {
      stroke: $dark-primary !important;
    }
  }

  &_accept {
    background-color: $dark-sys-success;

    &:hover {
      background-color: $dark-state-sys-success-hover;
    }

    &:active,
    &.-active {
      background-color: $dark-state-sys-success-press;
    }

    &_title {
      color: $dark-on-sys;
    }

    &_loader_circle {
      stroke: $dark-on-sys !important;
    }
  }

  &_decline {
    background-color: $dark-sys-danger;

    &:hover {
      background-color: $dark-state-sys-danger-hover;
    }

    &:active,
    &.-active {
      background-color: $dark-state-sys-danger-press;
    }

    &_title {
      color: $dark-on-sys;
    }

    &_loader_circle {
      stroke: $dark-on-sys !important;
    }
  }
}

.b-button-light {
  display: flex;
  justify-content: center;
  align-items: center;

  &.-disabled {
    opacity: $light-disabled;
  }

  // CustomButton type

  &_primary {
    background-color: $light-primary;

    &:hover {
      background-color: $light-state-primary-hover;
    }

    &:active,
    &.-active {
      background-color: $light-state-primary-press;
    }

    &_title {
      color: $light-on-primary;
    }

    &_loader_circle {
      stroke: $light-on-primary !important;
    }
  }

  &_secondary {
    background-color: $light-secondary;

    &:hover {
      background-color: $light-state-secondary-hover;
    }

    &:active,
    &.-active {
      background-color: $light-state-secondary-press;
    }

    &_title {
      color: $light-on-secondary;
    }

    &_loader_circle {
      stroke: $light-on-secondary !important;
    }
  }

  &_tertiary {
    background-color: transparent;
    border: 1px solid $light-secondary;

    &:hover {
      background-color: $light-state-secondary-container-hover;
    }

    &:active,
    &.-active {
      background-color: $light-state-secondary-container-press;
    }

    &_title {
      color: $light-secondary;
    }

    &_loader_circle {
      stroke: $light-secondary !important;
    }
  }

  &_filter-plain,
  &_text-plain {
    background-color: transparent;

    &:hover {
      background-color: $light-state-secondary-container-hover;
    }

    &:active,
    &.-active {
      background-color: $light-state-secondary-container-press;
    }

    &_title {
      color: $light-secondary;
    }

    &_loader_circle {
      stroke: $light-secondary !important;
    }
  }

  &_filter-activated,
  &_text-activated {
    background-color: transparent;

    &:hover {
      background-color: $light-state-primary-container-hover;
    }

    &:active,
    &.-active {
      background-color: $light-state-primary-container-press;
    }

    &_title {
      color: $light-primary;
    }

    &_loader_circle {
      stroke: $light-primary !important;
    }
  }

  &_selection-plain {
    background-color: $light-selection-off;

    &:hover {
      background-color: $light-state-selection-off-hover;
    }

    &:active,
    &.-active {
      background-color: $light-state-selection-off-press;
    }

    &_title {
      color: $light-secondary;
    }

    &_loader_circle {
      stroke: $light-secondary !important;
    }
  }

  &_selection-activated {
    background-color: $light-selection-on;
    border: 1px solid $light-primary;

    &:hover {
      background-color: $light-state-selection-on-hover;
    }

    &:active,
    &.-active {
      background-color: $light-state-selection-on-press;
    }

    &_title {
      color: $light-primary;
    }

    &_loader_circle {
      stroke: $light-primary !important;
    }
  }

  &_accept {
    background-color: $light-sys-success;

    &:hover {
      background-color: $light-state-sys-success-hover;
    }

    &:active,
    &.-active {
      background-color: $light-state-sys-success-press;
    }

    &_title {
      color: $light-on-sys;
    }

    &_loader_circle {
      stroke: $light-on-sys !important;
    }
  }

  &_decline {
    background-color: $light-sys-danger;

    &:hover {
      background-color: $light-state-sys-danger-hover;
    }

    &:active,
    &.-active {
      background-color: $light-state-sys-danger-press;
    }

    &_title {
      color: $light-on-sys;
    }

    &_loader_circle {
      stroke: $light-on-sys !important;
    }
  }
}
