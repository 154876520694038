.b-header {
  display: flex;
  align-items: center;
  width: 100%;
  max-height: 59px;
  height: 59px;
  justify-content: space-between;
  border-bottom: 1px solid #e2e7ec;

  &-left,
  &-right {
    display: flex;
    align-items: center;
    font-size: 14px;

    //burger menu style
    .navOpener {
      display: none;
      border: 0;
      width: 18px;
      height: 14px;
      background-color: transparent;
      flex-shrink: 0;
      position: relative;
      overflow: hidden;
      cursor: pointer;
      z-index: 11;

      @media screen and (max-width: 1200px) {
        display: block;
      }
      @media screen and (max-width: 767px) {
        width: 14px;
        height: 12px;
      }

      &:before,
      &:after,
      span {
        background-color: #b3c3d3;
        position: absolute;
        top: 50%;
        left: 0;
        right: 0;
        height: 2px;
        margin-top: -1px; /* height/2 */
        transition: all 0.2s linear;
      }
      &:before,
      &:after {
        content: '';
        top: 0;
        margin: 0;
      }
      &:after {
        top: auto;
        bottom: 0;
        margin: 0;
      }

      .isMenuOpen & {
        height: 18px;

        @media screen and (max-width: 767px) {
          height: 14px;
        }

        &:after,
        &:before {
          transform: rotate(45deg);
          top: 50%;
          margin-top: 0;
        }
        &:after {
          transform: rotate(-45deg);
        }

        span {
          display: none;
        }
      }
    }
  }

  &-logo {
    width: 80px;
    display: flex;
    justify-content: center;

    svg {
      width: 32px;
      height: 28px;
      cursor: pointer;
    }

    @media screen and (max-width: 510px) {
      width: 60px;
    }
  }

  //&-help {
  //  margin-right: 10px;
  //  //cursor: pointer;
  //
  //  @media screen and (max-width: 980px) {
  //    margin: 0 10px 0 10px;
  //  }
  //  @media screen and (max-width: 767px) {
  //    margin: 0 5px !important;
  //  }
  //  @media screen and (max-width: 400px) {
  //    display: none;
  //  }
  //
  //  svg {
  //    @media screen and (max-width: 767px) {
  //      width: 15px;
  //      height: 15px;
  //    }
  //  }
  //}

  &-left {
    @media screen and (max-width: 1200px) {
      padding-left: 30px;
    }
    @media screen and (max-width: 824px) {
      padding-left: 22px;
    }
    @media screen and (max-height: 450px) {
      padding-left: 20px;
    }
  }

  &-right {
    padding-right: 23px;
    @media screen and (max-width: 610px) {
      padding-right: 0;
    }
  }

  &-help {
    position: relative;

    &-icon {
      display: flex;
      margin-left: 26px;

      svg {
        width: 24px;
        height: 24px;
      }
    }

    &-menu {
      position: absolute;
      top: 30px;
      right: 0;
      display: flex;
      width: max-content;
      height: max-content;
      flex-direction: column;
      background-color: #f1f4f8;

      &-header {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 26px;
        background-color: #e2e7ec;

        span {
          color: #38455e;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  &-user {
    display: flex;
    margin-left: 26px;

    &-info {
      display: flex;
      flex-direction: column;
      min-width: 92px;

      @media screen and (max-width: 980px) {
        max-width: 120px;
        .username,
        .role {
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }

      @media screen and (max-width: 610px) {
        display: none;
      }

      .username {
        flex-direction: column;
        color: var(--color);
        font-size: 12px;
        font-weight: normal;
        white-space: nowrap;
      }

      .role {
        color: var(--color-noSelect);
        font-weight: 400;
        font-size: 12px;
        white-space: nowrap;
      }
    }

    &-logo {
      img {
        width: 24px;
        height: 24px;
        border-radius: 50%;
        border: 1px solid rgba(255, 255, 255, 0.3);
        -o-object-fit: cover;
        object-fit: cover;
        overflow: hidden;
        margin-right: 11px;
        @media screen and (max-width: 767px) {
          margin-right: 0;
        }
      }
    }
  }

  &-logout {
    margin-left: 8px;
    @media screen and (max-width: 767px) {
      margin-left: 0px;
    }
  }

  &-projectSelect {
    .wrapAsideSelect {
      border-radius: 0;

      @media screen and (max-width: 768px) {
        max-width: 157px !important;
        width: 157px !important;
      }
      @media screen and (max-width: 510px) {
        max-width: 100px !important;
        width: 100px !important;
      }
      .aside__select {
        height: 35px;
        color: var(--color) !important;
        border-radius: 0 !important;
        font-family: Proxima Nova;

        fieldset {
          border: none;
        }
      }
    }
    &:last-child {
      margin-left: 20px;
    }
  }
}
