.loader {
  color: white;
  position: fixed;
  top: 40%;
  left: 50%;
  display: flex;
  align-items: center;
  flex-direction: column;
  transform: translateX(-50%);
  z-index: 999;
}

.light-loader {
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
}
