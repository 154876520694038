.nfStatistics {
  foreignObject {
    width: 140px;
    height: 60px;
  }

  &.-expanded {
    foreignObject {
      width: 325px;
      height: 110px;
    }
  }
}

.b-statistics {
  position: relative;
  width: 120px;
  height: 40px;
  font-size: 12px;
  background: #f1f4f8;
  border: 1px solid #5e739e;
  border-radius: 2px;
  padding: 7px;

  &.-expanded {
    width: 290px;
    height: 90px;
  }

  &-container {
    padding: 0 5px 0 5px;
  }

  &-mainLabels {
    display: flex;
    flex-direction: column;
    width: 80%;
    height: inherit;
    justify-content: space-between;

    .mainLabel {
      margin-top: 2px;
      display: flex;
      justify-content: space-between;

      span {
        width: 40%;
        font-size: 12px;
        line-height: 17px;
        color: var(--color-secondary);

        &:first-child {
          width: 60%;
          text-align: left;
        }
      }
    }
  }

  &-table {
    display: flex;
    flex-direction: column;
    align-items: flex-start;

    .tableBody {
      margin-top: 7px;
      overflow: auto;
      height: 62px;
    }

    .tableHeader,
    .tableRow {
      display: flex;
      align-items: center;

      .name-label:hover {
        color: var(--color);
      }

      span {
        color: var(--color-secondary);
      }
    }

    .no-options {
      color: var(--color-secondary);
    }

    .tableRow {
      cursor: default;
      text-align: center;

      span {
        width: 70px;
        margin: 7px 10px 0 0;
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;

        &:first-child {
          text-align: left;
          width: 90px;
        }
      }
    }

    .tableHeader {
      width: 100%;
      cursor: default;
      border-bottom: 1px solid var(--color-secondary);

      span {
        width: 70px;
        margin: 0 10px 3px 0;

        &:first-child {
          text-align: left;
          width: 90px;
        }
      }
    }
  }

  &-content {
    height: 100%;
    width: 100%;
    display: flex;
    position: relative;
  }
}

.expandSvg {
  width: 20px;
  height: 30px;
  position: absolute;
  right: 3px;
  top: 12px;
  display: flex;
  justify-content: center;
  align-items: center;

  svg {
    width: 13px;
    height: 13px;

    path {
      fill: var(--color-secondary);
    }
  }

  &.-expanded {
    top: 35px;
  }
}
