.b-toolbar {
  &-panel {
    width: 280px;
    //max-height: 500px;
    background: #f1f4f8;
    box-shadow: 2px 2px 4px rgba(179, 195, 211, 0.5);
    border-radius: 3px;
    display: flex;
    flex-direction: column;

    &.schedulesEditPopUp {
      position: fixed;
      top: 0;
      left: 290px;

      @media screen and (max-width: 800px) {
        position: fixed;
        z-index: 1;
        top: 50px;
        left: 0;
        box-shadow: 0 0 4px 4px rgba(179, 195, 211, 0.7);
      }
    }

    &-header {
      display: flex;
      justify-content: center;
      align-items: center;
      font-weight: 400;
      font-size: 14px;
      height: 33px;
      min-height: 33px;
      background: #e2e7ec;
      padding: 0 7px;

      &-action {
        position: absolute;
        right: 10px;
      }

      &.-space {
        justify-content: space-between;
      }
    }

    &-container {
      display: flex;
      justify-content: center;
      flex-direction: column;
      padding: 12px 0;
      position: relative;

      .btn {
        padding: 10px 16px;
        transition: none;
        &:hover {
          background: #e2e7ec;
        }
        &.-m-16 {
          margin: 0 16px;
        }
        svg {
          margin-right: 13px;
        }
      }
    }

    &-list {
      display: flex;
      flex-direction: column;
      margin-bottom: 6px;
      overflow: auto;
      max-height: 400px;

      @media screen and (max-width: 800px) {
        max-height: 150px;
      }

      &-item {
        display: flex;
        justify-content: space-between;
        padding: 0px 16px;
        background: #f1f4f8;

        &:hover {
          background: #e2e7ec;
          .b-toolbar-panel-list-item-action {
            display: flex;
          }
        }

        &-stonesBoxes {
          display: flex;
          justify-content: space-between;
          align-items: center;
          padding: 4px 16px;
          background: #f1f4f8;
          .container {
            width: 100%;
            height: 20px;
            background: #ffffff;
            padding: 5px;
            &:hover {
              background: #e2e7ec;
              .b-toolbar-panel-list-item-action {
                display: flex;
              }
            }
          }
        }

        &-info {
          display: flex;
          align-content: space-between;
          cursor: pointer;
          height: calc(34px + 12px * 2);
          width: 100%;
          align-items: center;

          &-schedule {
            display: flex;
            cursor: pointer;
            align-items: center;
            width: 100%;
            max-width: 184px;
            justify-content: space-between;
          }

          &-icon {
            min-width: 0 !important;
            margin-right: 12px;
          }
        }

        &-wrap {
          display: flex;
          flex-direction: column;
          align-items: baseline;
          font-size: 14px;
        }

        &-title {
          color: #38455e;
          &-stonesBoxes {
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 12px;
          }
          &-date {
            white-space: nowrap;
            font-size: 12px;
          }
        }
        &-text {
          color: #838ea2;
        }
        &-palette {
          width: 17px;
          height: 17px;
          border-radius: 2px;
          margin-right: 10px;
          display: flex;
          justify-content: center;
          align-items: center;

          svg {
            width: 11px;
            height: 12px;
          }
        }

        &-action {
          width: 40px;
          display: none;
          justify-content: center;
          align-items: center;
          //height: 20px;
          button {
            padding: 0 !important;
          }
        }
      }
      &.scheduleList {
        max-height: 360px;
      }
    }
    &-dataList {
      position: absolute;
      left: calc(100% + 10px);
      top: -30px;
      border-radius: 3px;
      max-height: 485px;
      box-shadow: 2px 2px 4px rgba(179, 195, 211, 0.5);
      background: #f1f4f8;
      width: max-content;
      display: flex;
      flex-direction: column;

      @media screen and (max-width: 800px) {
        left: 0;
        top: 430px;
        z-index: 2;
        box-shadow: 2px 2px 4px 4px rgba(179, 195, 211, 0.7);
      }
    }
    &-manual {
      &-item {
        display: flex;
        justify-content: space-between;
        align-items: center;
        background: #ffffff;
        margin: 4px 6px;

        .actions {
          margin-left: 10px;
          display: flex;
          align-items: center;
        }
        .remove {
          display: flex;
          justify-content: center;
          align-items: center;
          min-width: 30px;
        }
        .date {
          font-size: 12px;

          &.-pointer {
            cursor: pointer;
          }
        }
      }
    }
  }
  &-form {
    &-item {
      display: flex;
      flex-direction: column;
      margin: 5px;
      padding: 0 10px;

      &-stoneHeader,
      &-boxHeader {
        display: flex;
        padding-left: 16px;
        padding-top: 6px;
      }
    }
  }
  &-scheduleBody {
    max-height: 360px;
    overflow: auto;
  }
  &-colorsBox {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-left: 10px;

    svg {
      width: 30px;
      height: 30px;
    }
  }

  &-colorPicker {
    display: flex;
    padding: 10px 0 10px 10px;
    background: #fff;
  }

  &-palette {
    width: 20px;
    height: 20px;
    border-radius: 1px;
    margin-right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    &:hover {
      box-shadow: rgba(179, 195, 211, 0.5);
    }

    &.choice {
      width: 18px;
      height: 18px;
      border: 1px solid #002556;
    }

    svg {
      width: 11px;
      height: 12px;
      // path {
      //   fill: #002556;
      // }
    }
  }

  &-popover {
    position: absolute;
    left: 285px;
    bottom: 0;
    z-index: 2;

    @media screen and (max-width: 800px) {
      position: absolute;
      left: 30px;
      bottom: 95px;
      z-index: 2;
      box-shadow: 0 0 4px 4px rgba(179, 195, 211, 0.7);
    }
  }

  &-cover {
    position: fixed;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;

    &.schedules {
      width: 285px;
      left: 290px;
    }
  }

  &-btns {
    margin-top: 10px;
    display: flex;
    justify-content: space-between;
    padding: 0 15px;
  }
}

.chrome-picker {
  background: #f1f4f8 !important;
  box-shadow: 2px 2px 4px rgba(179, 195, 211, 0.5) !important;
  // &:last-child {
  //   .flexbox-fix:first-child {
  //       div:first-of-type div:first-of-type{
  //         border-radius: 1px !important;
  //         // width:6px !important;

  //       }

  //   }
  //   }
}
