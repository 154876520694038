.nfTooltip {
  foreignObject {
    width: 350px;
    height: 175px;
    @media screen and (max-width: 500px) {
      height: 185px;
    }
  }
}

.b-tooltip {
  position: fixed;
  width: 330px;
  height: 155px;
  font-size: 12px;
  background: #f1f4f8;
  border: 1px solid #5e739e;
  border-radius: 2px;
  padding: 7px;

  @media screen and (max-width: 500px) {
    width: 320px;
    height: 155px;
  }
  @media screen and (max-width: 400px) {
    width: 289px;
  }

  &-container {
    height: 100%;
  }

  &-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    border-bottom: 1px solid var(--color-secondary);
    padding-bottom: 5px;

    .left,
    .right {
      display: flex;
      align-items: center;

      span {
        margin-right: 5px;
      }
    }

    .left {
      width: 60%;

      svg {
        margin-right: 7px;
      }
    }

    .right {
      width: 40%;
    }

    .color-circle {
      margin-right: 7px;
      width: 10px;
      height: 10px;
      border-radius: 50%;
    }

    &:before {
      contant: '';
      position: absolute;
      left: 0;
      bottom: 0;
      width: 100%;
      height: 1px;
      background: #838ea2;
    }
  }

  &-dates {
    margin-top: 5px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .nfStatus,
  .reqType {
    overflow-x: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
  }

  .nfStatus {
    width: 60%;
    max-width: 60%;
  }

  .reqType {
    width: 90%;
    max-width: 90%;
  }

  .gray {
    color: #838ea1;
  }

  .scheduler {
    display: flex;
    align-items: center;
  }

  &-content {
    margin-top: 5px;

    &-request,
    &-response {
      display: flex;
      flex-direction: column;
      position: relative;
      padding: 0 10px;
      .line {
        position: absolute;
        width: 4px;
        height: 30px;
        top: 3px;
      }
    }

    &-request {
      align-items: baseline;
      .line {
        left: 0;
      }
    }

    &-response {
      align-items: flex-end;
      .line {
        right: 0;
      }
    }
  }

  &-ufmf {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #838ea1;
    margin-top: 5px;

    .uf,
    .mf {
      display: flex;
      width: 49%;
      max-width: 49%;
      overflow: hidden;

      span {
        color: var(--color-secondary);
      }

      .codes {
        overflow-x: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }
    }

    .uf {
      justify-content: flex-start;

      .bold {
        margin-right: 5px;
      }
    }

    .mf {
      justify-content: flex-end;

      .bold {
        margin-left: 5px;
      }
    }

    .bold {
      font-weight: bold;
    }
  }

  &-footer {
    display: flex;
    justify-content: space-between;
    align-items: end;
    margin-top: 38px;

    @media screen and (max-width: 500px) {
      margin-top: 25px;
    }

    .b-tooltip-footer-left {
      display: flex;

      .del-revision {
        box-sizing: border-box;
        width: 28px;
        height: 14px;
        font-size: 12px;
        line-height: 14px;
        color: var(--color-rdg);
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        align-items: center;

        &.-superseded {
          background-color: #e2e7ec;
          border: 1px solid #e2e7ec;
          color: #0c1627;
        }

        &.-current {
          background-color: #66c17f;
        }

        &.-not_issued_yet {
          background-color: transparent;
          border: 1px solid #38455e;
          color: #38455e;
        }

        &.-validation_required {
          background-color: #f3a359;
        }
        &.-critical {
          background-color: #a92d2d;
        }
        &.-outdated {
          background-color: #a92d2d;
          border: 1px solid #a92d2d;
          color: #38455e;
        }
      }

      .del-status {
        font-size: 14px;
        margin-left: 10px;
      }
    }

    .b-tooltip-footer-right {
      display: flex;
      font-size: 14px;

      .del-rew-title {
        margin-right: 8px;
      }

      .del-rew-state {
        display: flex;
        align-items: end;
      }
    }
  }
}
