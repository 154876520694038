@import '../../../assets/styles/highResolution';

.b-tokenIcon-dark,
.b-tokenIcon-light {
  &.-clickable {
    cursor: pointer;
  }

  .highResolution & {
    @media screen and (min-width: $resolution-2k) {
      transform: scale($resolution-2k-multiplier);
    }

    @media screen and (min-width: $resolution-4k) {
      transform: scale($resolution-4k-multiplier);
    }
  }
}
